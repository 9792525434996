// Colors

$bodyBgColor: #F5F6FA;

$mainContentBgColor: #EEE;

$topBarBgColor: #009688;
$topBarItemHoverBgColor: #00877B;

$linkColor: #9158D9;
$topBarTextColor: #FFF;

$sidebarBgColor: #FFF; // #222D32;
$sidebarMenuTextColor: #DDD;
$sideBarHoverBgColor: #1C1F22;
$sidebarMenuHoverTextColor: #FFF;

$signInBgColor: #009688;
$headerFontSize: 24px;

$buttonDefaultTextColor: #FFF;
$buttonDefaultBgColor: #9158D9;

$pageHeaderBgColor: #DDD;
$panelDivBgColor: #FFF;

// Forms

$formFieldTopBottomMargin: 6px;
$formFieldLeftRightMargin: 0;
$inputFieldsBgColor: #FFF;
$inputFieldBorderColor: #DDD;
$inputTextHeight: 30px;

// Pring NG

// $fontFamily: 'Inter', sans-serif;s
$fontFamily: 'Montserrat', sans-serif;
$fontSize: 14px;
$borderRadius: 2px;
$disabledOpacity: .35;
$fontColor: #000;

// Header
$headerBorderWidth: 1px;
$headerBorderColor: #FFF;
$headerBgColor: #FFF;
$headerTextColor: #444;
$headerFontWeight: bold;
$headerIconTextColor: #444;

// Content
$contentBorderWidth: 1px;
$contentBorderColor: #DDD;
$contentBgColor: #FFF;
$contentTextColor: #444;

// Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: #DDD;
$stateDefaultBgColor: #F6F6F6;
$stateDefaultTextColor: #0073EA;

// Active State
$stateActiveBorderColor: #DDD;
$stateActiveBgColor: #FFF;
$stateActiveTextColor: #9158D9;

// Highlight State
$stateHighlightBorderColor: #9158D9;
$stateHighlightBgColor: #9158D9;
$stateHighlightTextColor: #FFF;

// Focus State
$stateFocusBorderColor: #0073EA;
$stateFocusBgColor: #0073EA;
$stateFocusTextColor: #FFF;

// Error State
$stateErrorBorderColor: #DDD;
$stateErrorBgColor: #FFF;
$stateErrorTextColor: #AB0F0F;

// Hover State
$stateHoverBorderColor: #0073EA;
$stateHoverBgColor: #0073EA;
$stateHoverTextColor: #FFF;

// Forms
$inputBgColor: #FFF;
$inputTextColor: #444;
$invalidInputBorderColor: #FF0084;

// Glow messages
$growlMessageErrorColor: #F44336;
$growlMessageSuccessColor: #4CAF50;

// Grid
$gridHeaderTextColor: #FFF;
$gridHeaderBgColor: $topBarBgColor;
$gridHeaderBorderColor: $topBarBgColor;
$gridBtnColorDanger: #D9534F;
$gridBtnColorDangerFocus: #C9302C;
$gridBtnColorSuccess: #5CB85C;
$gridBtnColorSuccessFocus: #449D44;

$loaderBgColor: transperent;
$white: #FFF;
$backgroundColor: #F5F6FA;
$labelColor: #1B3F4E;
$textColor: #3F5057;
$primaryColor: #29ABE2;
$primaryHoverColor: #29ABE2;
$customerBackgroundColor: #1592E6;
$placeholderColor: #707070;
$buttonBackgroundColor: #F7D41F;
$footerBackgroundColor: #1885CE;
$dropdownColor: #1A2D3E;
$productBackgroundColor: #79CEF0;
$customerPlaceholderColor: #D6D6D6;
$countBackgroundColor: #096798;
$dialogBackgroundColor: #C4F0FF; //#C4F0FF 3d4246;
$titleBarColor: #333;  //#FFF #333
$payBackgroundColor: #28D155;
$failedColor: #F33F38;
$franchiseHeaderColor: #32455F;
$FranchiseSideBarHoverBgColor: #DBDBDB;

$grey: #808080;

// franchise
$black: #000;
$red: #F00;
$blue: #00F;
$blueDropdown: #29ABE2;
$yellowDropdown: #F3B12C;
$greenDropdown: #079944;
$redDropdown: #E55039;
$campaigntextColor: #002C80;
$color1: #06A0FF;
$color2: #DDD;
$color3: #32455F;
$color4: #F4F5FA;
$color5: #3F5057;
// $color6: #1A1A1A87;
// $color7: #3D424652;
$color6: #1A1A1A;
$color7: #3D4246;
$color8: #25A4B4;
$color9: #F9F9F9;
$color10: #E3EAFA;
$color11: #979292;
$color12: #D7D7D7;

$sidebarColor: #707070;
$sidebarActiveColor: #568EE8;

$hoverColor: #5DB3B5;
