.p-0 {
  padding: 0;
}

// Hover style
.primary-btn,
.ui-button {
  overflow: hidden;
  position: relative;
  transition: all .5s;
  z-index: 1;

  &:after {
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: -2;
  }

  &:before {
    background-color: #5DB3B5;
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    transition: all .3s;
    width: 0%;
    z-index: -1;
  }

  &:hover {
    color: #FFF;

    &:before {
      width: 100%;
    }
  }
}

.p-panel .p-panel-header {
  background: transparent;
  border: 0;
}

.signin-box-container {
  background: $sidebarBgColor;
  display: table;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .signin-box-inner-container {
    align-items: center;
    display: inline-flex;
    vertical-align: middle;
    width: 100%;

    .company-logo {
      padding: 13px 13px 4px;
      text-align: left;

      img {
        cursor: auto;
        width: 71px;
      }
    }

    .signin-image-wrapper {
      img {
        width: 100%;
      }
    }

    .signin-box {
      margin: initial;
      padding-left: 40px;
      padding-right: 40px;
      position: relative;

      .login-btn {
        align-items: center;
        display: flex;
        margin-bottom: 6px;
        margin-left: 0;
        margin-top: 0;
        max-height: 40px;
        min-height: 40px;
        padding: 5px 30px;

        &:disabled {
          cursor: not-allowed;
        }
      }

      .forgot-password-link {
        font-size: 12px;
      }

      .form-buttons {
        display: inline-block;
      }
    }

    .ui-panel-titlebar {
      display: none;
    }
  }
}

@media screen and (min-width: 1365px) {
  .signin-box-inner-container {
    .signin-image-wrapper {
      width: 53.5%;

      img {
        width: 100vh;
      }
    }
  }
}

@media screen and (max-width: 40em) {
  .signin-box-container {
    .signin-box-inner-container {
      .signin-box {
        left: 8.335%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .signin-box-container {
    background: #F5F7F9;

    .signin-box-inner-container {
      background: #F5F7F9;

      .signin-box {
        .ui-panel {
          background: #F5F7F9;

          .ui-panel-content {
            background: #F5F7F9;
          }
        }

        .company-logo {
          img {
            margin-left: 0;
          }
        }

        .form-buttons {
          display: block;
        }

        .login-btn {
          justify-content: center;
          margin: 0;
          min-height: 47px;
          width: 100%;
        }
      }
    }
  }

  .extend-width.franchise-selection-popup {
    width: 95%;
  }
}

.franchise-selection-popup {
  .franchise-name {
    // padding-bottom: 5px;
    bottom: 4.5px;
    color: #444;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    padding-left: 10px;
    position: relative;
  }

  .p-dialog-content {
    min-height: 200px;

    .p-dropdown-panel {
      .p-dropdown-items-wrapper {
        max-height: 100px !important;
      }
    }

  }

  .franchise-list {
    padding: 0;
  }

  input[type=radio] {
    border: 1px solid #568EE8;
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }

  input[type=radio]:checked:before {
    background: #568EE8;
  }
}

.role-selection-popup {
  .p-dialog-content {
    min-height: 150px;
  }
}
