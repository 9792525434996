* {
  box-sizing: border-box;
}

body {
  color: $BodyTextColor;
  font-family: $fontFamily;
  font-size: $fontSize;
  margin: 0;
  padding: 0;

  .ui-radiobutton {
    height: 20px;
    outline: none;
    width: 20px;

    .ui-radiobutton-box {
      /* height: 15px;
      margin-top: 3px;
      width: 15px; */
      -webkit-appearance: none;
      // border-color: #007AD9;
      background-color: #FFF;
      border: 1px solid #568EE8;
      border-radius: 50%;
      color: #FFF;
    }

    .ui-radiobutton-icon {
      background: transparent;
      height: 9px;
      left: 52%;
      top: 52%;
      width: 9px;
    }
  }
}

.ui-radiobutton-label {
  margin: 0 .4em 0 0;
}

.col-nogutter {
  padding: 0;
}

.col-nogutter {
  padding: 0;
}

.qrcode {
  display: none;
}

.bold-text {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.italic-text {
  font-style: italic;
}

.align-center {
  text-align: center;
}

.align-end {
  text-align: end;
}

.mrg-top-10 {
  margin-top: 10px;
}

.image-code {
  background: url('/assets/images/temp/sprite.png') 0 0;
}

.image-rating-width {
  height: 25px;
  width: 25px;
}

.rating1 {
  background-position: -69px -38px;
}

.rating2 {
  background-position: -119px -38px;
}

.rating3 {
  background-position: -168px -38px;
}

.rating4 {
  background-position: -219px -38px;
}

.rating5 {
  background-position: -269px -38px;
}

.track-driver {
  cursor: pointer;
  float: right;

  &:hover {
    color: $defaultThemeColor;
  }
}

input {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

a {
  color: $linkColor;

  &:active,
  &:focus,
  &:link {
    color: $linkColor;
    outline: none;
    text-decoration: none;
  }

  &:hover {
    color: $stateFocusBgColor;
    outline: none;
    text-decoration: none;
  }
}

//  Toast Style

body {
  .ui-toast {
    .ui-toast-message {
      .ui-toast-message-error {
        background-color: #FFCDD2;
        border: 0 none;
        border-left: 3px solid #E60017;
        border-radius: 5px;
        color: #73000C;
        opacity: .9 !important;
      }

      .ui-toast-message-success {
        background: #C8E6C9;
        border: solid #439446;
        border-width: 0 0 0 6px;
        color: #224A23;
        opacity: .9 !important;
      }

      .ui-toast-message-info {
        background: #B3E5FC;
        border: solid #0891CF;
        border-width: 0 0 0 6px;
        color: #044868;
        opacity: .9 !important;
      }

      .ui-toast-message-warn {
        background: #FFECB3;
        border: solid #D9A300;
        border-width: 0 0 0 6px;
        color: #6DD100;
        opacity: .9 !important;
      }
    }
  }

  .ui-toast-close-icon {
    position: absolute;
    right: 1em;
    top: 1em;
  }

  .ui-toast-message-content {
    align-items: flex-start;
    display: flex;
    padding: 1em;
  }
}

// body .ui-toast .ui-toast-message.ui-toast-message-error {
//   background-color: #FFCDD2;
//   border: 0 none;
//   border-left: 3px solid #E60017;
//   border-radius: 5px;
//   color: #73000C;
//   opacity: .9 !important;
// }

// body .ui-toast .ui-toast-message.ui-toast-message-success {
//   background: #C8E6C9;
//   border: solid #439446;
//   border-width: 0 0 0 6px;
//   color: #224A23;
//   opacity: .9 !important;
// }

// body .ui-toast .ui-toast-message.ui-toast-message-info {
//   background: #B3E5FC;
//   border: solid #0891CF;
//   border-width: 0 0 0 6px;
//   color: #044868;
//   opacity: .9 !important;
// }

// body .ui-toast .ui-toast-message.ui-toast-message-warn {
//   background: #FFECB3;
//   border: solid #D9A300;
//   border-width: 0 0 0 6px;
//   color: #6DD100;
//   opacity: .9 !important;
// }

// .ui-toast .ui-toast-close-icon {
//   position: absolute;
//   right: 1em;
//   top: 1em;
// }

// .ui-toast .ui-toast-message-content {
//   align-items: flex-start;
//   display: flex;
//   padding: 1em;
// }

// Toast style end

.page-header {
  background-color: $pageHeaderBgColor;
}

.page-buttons {
  button {
    float: right;
    margin: 0 0 0 8px;
  }
}

.ql-editor {
  background: $white;
  height: 100px;
  overflow-y: auto;
  resize: vertical;
}

p-tabmenu {
  .ui-widget-content {
    border: 0 none;
  }
}

ul {
  &.ui-widget-header {
    border: 0 none;

    .ui-tabmenuitem {
      a {
        color: $inputTextColor;
      }

      &.ui-state-active {
        a {
          color: $gridHeaderTextColor;
        }
      }
    }
  }
}

.ui-tabmenu-nav {
  background: $stateDefaultBgColor;
  border: 0;

  li {
    &.ui-tabmenuitem {
      background-color: $stateDefaultBgColor;

      &:not(.ui-state-active) {
        background-color: $sideBarHoverBgColor;

        &:hover {
          background-color: $secondaryThemeColor;
          border-color: $stateDefaultBorderColor;

          a {
            color: $sidebarIconColor;
          }
        }

        a {
          color: $sidebarIconColor;
        }
      }
    }
  }
}

.panel-div {
  background-color: $panelDivBgColor;
  padding: 8px;
}

.panel-div-top-info {
  padding: 0 0 8px;

  .left-info {
    text-align: left;
  }

  .right-info {
    text-align: right;
  }
}

h1 {
  &.page-title {
    font-size: 18px;
  }
}

.bread-crumbs {
  float: right;
  padding: 14px 5px;
}

.bread-item {
  display: inline-block;
  list-style-type: none;
}

.ui-panel {
  .ui-widget-header {
    font-size: $headerFontSize;
    font-weight: bold;
  }

  .ui-panel-titlebar {
    padding: 8px 16px;
  }

  .ui-panel-content {
    background: none;
    border: 0;
    padding: 8px 16px;
  }
}

.donation-amount-btn {
  .ui-button {
    &.ui-state-default {
      background: #28D155;
      border: 1px solid $buttonDefaultBgColor;
      border-radius: 5px;
      color: $buttonDefaultTextColor;
      font-size: 16px;
      font-weight: 500;
      width: 75%;

      &:hover {
        background: $buttonHoverColor;
        border: 1px solid $buttonHoverColor;
      }
    }
  }
}

.ui-button-text-only {
  .ui-button-text {
    padding: 8px 16px;
  }
}

.ui-button-text-icon-left {
  font-size: 18px;

  .ui-button-text {
    font-size: 13px;
    padding: 8px 10px 8px 36px;
  }
}

.ui-growl-item-container {
  &.ui-state-highlight {
    &.ui-growl-message-success {
      background-color: $growlMessageSuccessColor;
      border-color: $growlMessageSuccessColor;
    }

    &.ui-growl-message-error {
      background-color: $growlMessageErrorColor;
      border-color: $growlMessageErrorColor;
    }
  }
}

.partner-box {
  background: $white;
  border: 1px solid $inputFieldBorderColor;
  color: $headerTextColor;
  margin-bottom: 20px;
}

.out-partner-info {
  cursor: pointer;
  text-align: right;
}

.dynamic-field {
  margin-top: 20px;
}

.form-label label {
  color: #707070;
}

.lang-right-to-left {

  .form-label,
  .ui-panel-titlebar,
  a,
  p {
    direction: rtl;
  }

  input {
    &[type='text'] {
      direction: rtl;
    }
  }

  .ui-panelmenu {
    .ui-menuitem-icon {
      &.fa {
        margin-left: .5em;
      }
    }
  }
}

.assign {
  .ui-inputtext {
    &.ui-state-default {
      height: 32px;
      padding: 0 10px;
      width: 100%;
    }
  }

  .ui-autocomplete {
    width: 100%;

    .ui-autocomplete-dropdown {
      background-color: $bodyBgColor;
      border: 0 none;
      color: $defaultThemeColor;
      height: 29px;
      position: absolute;
      right: 1px;
      top: 9px;
      vertical-align: top;
      width: 22px;
    }
  }

  &.search-driver {
    .ui-autocomplete-dropdown {
      background-color: $bodyBgColor;
      border: 0 none;
      color: $defaultThemeColor;
      height: 29px;
      position: absolute;
      right: 1px;
      top: 1px;
      vertical-align: top;
      width: 22px;
    }
  }
}

.web-link {
  color: $stateDefaultTextColor;
}

.caps {
  text-transform: uppercase;
}

.map {
  height: 100%;
  width: 100%;

  &.min-height {
    min-height: 500px;
  }
}

.sidebar {
  left: 0;
}

.pd-5 {
  padding: 0 5px !important;
}

.p-ml-1 {
  margin-left: .25rem !important;
}

.p-ml-2 {
  margin-left: .5rem !important;
}

.p-mr-1 {
  margin-right: .25rem !important;
}

.p-mr-2 {
  margin-right: .5rem !important;
}

.p-mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.p-align-center {
  align-items: center;
}

.p-justify-even {
  justify-content: space-evenly;
}

.p-0 {
  padding: 0 !important;
}

.mob-p-0 {
  @media screen and (max-width: 767px) {
    padding: 0 !important;
  }
}

.p-align-end {
  align-items: flex-end;
}

.p-dir-col {
  flex-direction: column;
}

.p-m-0 {
  margin: 0 !important;
}

.p-m-.grid {
  margin: 0 !important;
}

.p-my-2 {
  margin-bottom: .5rem !important;
  margin-top: .5rem !important;
}

.p-my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.p-my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

.p-justify-end {
  justify-content: flex-end;
}

.eclipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .row-item {
    display: flex;
    flex-direction: row;
  }

  .assign-asset {
    color: #00F;
    cursor: pointer;
    white-space: pre-wrap;
  }

  .clickable {
    color: #00F !important;
    cursor: pointer;

    &.address {
      font-size: 12px;
    }
  }

}

.invoice-status {
  color: #FFF !important;
  padding: 0 !important;

  .draft, .quotation_draft {
    background-color: #fbbf24;
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 10px;
    // opacity: 0.5;
  }

  .refunded, .partial_refunded {
    background-color: #fb7185;
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 10px;
    // opacity: 0.5;
  }
  .overdue, .quotation_rejected {
    background-color: #ef4444;
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 10px;
    // opacity: 0.5;
  }
  .sent, .quotation_sent{
    background-color: #38bdf8;
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 10px;
    // opacity: 0.5;
  }
  .quotation_accepted {
    background-color: #22d3ee;
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 10px;
    // opacity: 0.5;
  }
  .paid {
    background-color: #16a34a;
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 10px;
    // opacity: 0.5;
  }
  .partial_paid {
    background-color: #4ade80;
    align-items: center;
    display: flex;
    height: 48px;
    padding-left: 10px;
    // opacity: 0.5;
  } 
}


.cond-color {
  &.blue {
    color: #29ABE2;
  }

  &.yellow {
    color: #F3B12C;
  }

  &.green {
    color: #079944;
  }

  &.red {
    color: #E55039;
  }
}

#contentPlaceholder {
  p-table {
    .p-datatable-table:has(.email-status) {
      .p-datatable-thead {
        tr {
          th {
            font-size: 14px !important;
          }
        }
      }

      .p-datatable-tbody {
        tr {
          td {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

.email-status {
  align-content: center;
  display: block !important;
  justify-content: center;
  padding: 0 !important;

  .statusbox {
    display: flex;
    height: 47px;
    justify-content: center;
    // padding-top: 12px;
  }

  .yesStatus {
    background-color: #C3F5D0;
  }

  .noStatus {
    background-color: #FFE9E1;
  }
}

.eventName {
  font-weight: 500;
  padding: 2px 0;
}

.pad-4 {
  padding: 4px 0;
}

.address {
  font-size: 12px;
  padding-bottom: 4px;
}

.address-order {
  color: #00F;
}

.staff {
  font-size: 12px;
  padding: 0 10px;
}

.manual-status {
  text-transform: capitalize;
}

.p-l-r-10 {
  padding: 0 5px;
}

.mi-pie-chart {
  display: inline-block;
  height: auto;
  width: 250px;
}

.mi-bar-chart {
  display: inline-block;
  height: 271px;
  width: 60%;
}

.flexbox {
  flex: 1;
  height: 233px;
}

.error {
  color: $defaultThemeColor;
  font-size: 16px;
}

.top-padding {
  padding-top: 3%;
}

.document {
  cursor: pointer;
}

.loader-hidden {
  visibility: hidden;
}

.loader-page-center-overlay {
  background-color: $loaderBgColor;
  bottom: 0;
  height: 70px;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 88px;
  z-index: 500000;
}

.info-data {
  display: inline-block;
}

.device-height {
  min-height: 100vh;
}

.add-edit-panel {
  padding: 30px 20px 20px;
}

.ui-button-icon-only {
  .ui-button-text {
    margin-top: -2px;
  }
}

.ui-autocomplete {
  .ui-autocomplete-dropdown {
    height: 30px;
    top: -1px;
    vertical-align: inherit;
  }
}

.user-pic {
  margin: 5% 15%;
  width: 70%;
}

p-calendar {
  &.short-input {
    .ui-calendar {
      width: 100%;

      input {
        &[type='text'] {
          width: 100%;
        }
      }
    }

    .ui-button {
      &.ui-state-default {
        right: 4%;
      }
    }
  }

  .ui-button {
    &.ui-state-default {
      border: 0;
      border-left: 0 none;
      color: $calendarIconColor;
      height: 32px;
      position: absolute;
      right: 0;
    }
  }

  input {
    width: 100%;
  }
}

.ui-spinner {
  .ui-spinner-up {
    font-size: 12px;
    height: 14px;
    margin: 8px 0 0;
  }

  .ui-spinner-down {
    font-size: 12px;
    height: 14px;
    margin: 0;
    top: 24px;
  }
}

.inner-container {
  padding: 0 15px;
}

// .grid {
//   margin-right: -.5rem !important;
//   margin-left: -.5rem !important;
//   margin-top: -.5rem !important;
// }

.p-justify-between {
  align-items: center;
  justify-content: space-between;
}

.gridparent {
  margin: 0 6px;
  padding: 0;
}

.p-fileupload-content {
  display: none;
  padding: 1em;
  position: relative;
  position: relative;
  transition: border-color .3s;
}

.display-image {
  &.profile-btn {
    .p-fileupload {
      .p-fileupload-buttonbar {
        position: relative;
        right: 15px;
        text-align: left;
        width: 185px;
      }
    }
  }
}

.p-fileupload {
  .p-fileupload-buttonbar {
    background: transparent;
    border: 0;
    text-align: center;
    // width: 185px;

    .p-button-icon-left {
      display: none;
    }
  }
}

.p-button.p-fileupload-choose input[type='file'],
.p-fileupload-choose.p-fileupload-choose-selected input[type='file'] {
  display: none;
}

.img-doc {
  max-width: 60px;
}

.active-box {
  background: $white;
  box-shadow: 0 3px 5px -2px rgba(0, 0, 0, .33);
  color: $activeBoxTextColor;
  margin-right: 20px;
  padding: 7px 15px;
  text-align: left;

  .special-count {
    float: right;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-0 {
  margin-top: 0;
}

.pt-0 {
  padding-top: 0;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.form-buttons {
  &.corporate-button-bar {
    margin-top: 60px;
  }

  button {
    &.destination-add-full-btn {
      margin: 0;
      width: 100%;
    }
  }
}

.reset-btn {
  background-color: $white;
  border: 1px solid $inputFieldBorderColor;
  border-radius: 4px;
  color: $defaultThemeColor;
  cursor: pointer;
  float: left;
  margin-top: 7px;
  padding: 6px 10px;
}

p-dropdown {
  .ui-dropdown-items {
    .ui-state-highlight {
      background: $dropdownBgColor;
      border-color: $dropdownBorderColor;
      color: $white;
    }
  }

  .ui-dropdown {
    &:not(.ui-state-disabled) {
      &:active {
        border-color: $inputFieldBorderColor;
      }

      &:hover {
        border-color: $inputFieldBorderColor;
      }
    }

    .p-dropdown-trigger {
      .fa {
        color: $defaultThemeColor;
      }
    }
  }

  .ui-inputtext {
    &:focus {
      border-color: $inputFieldBorderColor;
      box-shadow: none;
    }
  }
}

.ui-chkbox-box,
.ui-radiobutton-box {
  &.ui-state-default {
    background: $checkboxBackgroundColor;
    border: 1px solid;
    border-color: $checkboxActiveBorderColor;
    color: $checkboxCheckColor;
  }

  &:not(.ui-state-disabled) {
    &:not(.ui-state-active) {
      &:hover {
        background-color: $checkboxBackgroundColor;
        border-color: $checkboxActiveBorderColor;
      }
    }
  }

  &.ui-state-active {
    border-color: $checkboxActiveBorderColor;
  }
}

.form-field {
  .asterisk {
    color: $growlMessageErrorColor;
  }

  .employee-input {
    input {
      &[type='text'] {
        width: 100%;
      }
    }
  }

  .ui-dropdown-panel {
    input {
      &[type='text'] {
        width: 100%;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .ui-dropdown-filter-container {
      width: 100%;

      .fa {
        position: absolute;
        right: 21px;
        top: 21px;
      }
    }

    .ui-dropdown-item {
      &:not(.ui-state-highlight) {
        &:hover {
          background-color: $secondaryThemeColor;
          border-color: $secondaryThemeColor;
        }
      }
    }
  }
}

.delete-bin {
  &.fa {
    cursor: pointer;
    float: right;
    font-size: 16px;

    &:hover {
      color: $defaultThemeColor;
    }
  }
}

.document-container {
  width: 100%;
}

.ui-datepicker {
  .ui-datepicker-calendar {
    td {
      &:not(.ui-state-disabled) {
        a {
          &.ui-state-highlight {
            background-color: $secondaryThemeColor;
            border-color: $secondaryThemeColor;
            color: $white;
          }

          &.ui-state-active {
            background-color: $defaultThemeColor;
            border-color: $defaultThemeColor;
            color: $white;
          }

          &:hover {
            background-color: $defaultThemeColor;
            border-color: $defaultThemeColor;
          }
        }
      }

      .ui-state-disabled {
        padding: 0;
      }
    }
  }

  .ui-timepicker {
    padding: .857em 0 0 !important;
  }
}

.scroll-panel {
  overflow: scroll;
  padding: 0 0 16px;
}

.box-bottom-border {
  border-bottom: 1px solid $sidebarIconColor;
}

.mt-10 {
  margin-top: 10px;
}

.step-tab {
  .ui-steps-item {
    &.ui-state-highlight {
      background-color: $secondaryThemeColor;

      a {
        color: $white;

        &:focus {
          color: $white;
        }
      }

      &:hover {
        a {
          color: $white;
        }
      }
    }

    &.ui-state-disabled {
      a {
        color: $secondaryThemeColor;
      }
    }
  }
}

p-multiselect {
  .p-multiselect-label {
    padding: .65rem !important;
  }

  .p-multiselect {
    &:not(.ui-state-disabled) {
      &:hover {
        border-color: $inputFieldBorderColor;
      }
    }

    .p-multiselect-trigger {
      border: 0;
      color: $defaultThemeColor;
    }
  }

  .p-multiselect-panel {
    min-width: 100%;
  }
}

.p-multiselect-panel {
  position: absolute;
}

.event-mail-dialog {
  width: 60%;

  .p-dialog-content {
    padding-bottom: 0 !important;
  }
}

p-selectbutton {
  .ui-selectbutton {
    .ui-button {
      &:not(.ui-state-disabled) {
        &:not(.ui-state-active) {
          &:hover {
            background-color: $secondaryThemeColor;
            border-color: $secondaryThemeColor;
          }
        }
      }
    }
  }
}

p-chips {
  .ui-chips {
    .ui-inputtext {
      &:hover {
        border-color: $inputFieldBorderColor;
      }

      .ui-chips-token {
        &.ui-state-highlight {
          background-color: $defaultThemeColor;
          border-color: $defaultThemeColor;
        }
      }

      .ui-chips-input-token {
        input {
          &[type='text'] {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.right-align {
  padding-right: 3%;
  text-align: right;
}

.same-height-div {
  display: flex;

  .beside-div-same-height {
    background: $white;
    flex: 1;
    margin: 0 .5em .5em;
    padding: 0;
  }
}

p-dialog {
  .p-dialog-header {
    background: $secondaryThemeColor !important;
    color: $white !important;
    padding: 12px 16px !important;

    .p-dialog-header-icon:last-child {
      color: $white !important;
      background-color: transparent;
    }

    .p-dialog-header-icons{
      .p-button {
        background-color: transparent;
        min-height: 0;

        &::before {
          background-color: transparent;
        }
      }
    }
  }

  .p-dialog {
    background: $white;

    &.ui-corner-all {
      border: 0;
    }

    .p-dialog-titlebar {
      background: $secondaryThemeColor;
      border: 0;
      color: $white;
      font-size: 16px;
      padding: 1em 1.1em;
      position: relative;

      .p-dialog-titlebar-close {
        border: 0;
        color: $white;

        &:hover {
          background-color: $secondaryThemeColor;
          border: 0;
          color: $white;
        }
      }
    }

    .p-dialog-footer {
      overflow: hidden;
      padding: .8em;

      button {
        width: 85px;
      }
    }
  }
}

p-confirmdialog .p-dialog-header {
  // background-color: #3D4246 !important;
  background-color: #F44B4B !important;
  color: $white !important;
  display: block;
  padding: .75rem 1rem !important;
  text-align: left;

  .p-dialog-header-icons {
    float: right;

    .p-dialog-header-icon {
      height: auto;
    }
  }

  .p-dialog-header-icon:last-child {
    color: $white !important;
  }
}

@media screen and (min-width: 360px) and (max-width: 640px) {
  p-dialog {
    .p-dialog {
      position: initial;
      width: auto;
    }
  }
}

.assign-asset-dialog {
  height: 35%;
  width: 35%;
}

.add-event-dialog {
  // height: 35%;
  width: 60%;

  /* .p-dialog-content {
    overflow-y: hidden;
  } */
  // min-height: 90%; //temparary added can remove if needed
}

p-dialog .add-client-dialog {
  height: 48%;
  width: 35%;

  @media screen and (max-width: 767px) {
    height: 40%;
    width: 90%;
  }
}

//staff leave reason popup
p-dialog .leave-popup {
  width: 90%;

  .ok-btn {
    width: 100%;
  }

  .reason-info {
    font-weight: 600;
    padding-bottom: 20px;
  }
}

p-dialog .add-foodextra-dialog {
  max-height: 100%;
  width: 50VW;
}


p-dialog {
  .add-report-dialog {
    // height: 64%;
    width: 35%;

    .p-add-report-dialog {
      &.p-dialog {
        border: 0;
        box-shadow: none;
      }
    }

    @media screen and (max-width: 767px) {
      // height: 58%;
      width: 90%;
    }
  }
}

p-dialog {
  .events-Delete-confirm {
    .p-dialog-titlebar {
      background: #F44B4B;
      text-align: center;
    }

    .p-dialog-content {
      .page-buttons {
        .confrm-btn-save {
          background: #90C590;
          color: $white;
        }

        .confrm-cancel-btn {
          background: #29ABE2;
          color: $white;
        }
      }
    }

    &.add-client-dialog {
      height: auto;
    }
  }
}

p-dialog .update-serease-dialoge {
  height: 30%;
  width: 35%;
}

p-dialog .assign-staff-dialog {
  width: 72%;

  &.p-dialog .p-dialog-titlebar-icons {
    float: right;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  @media screen and (max-width: 760px) {
    height: 100%;
    width: 95%;

    .mob-cust-save {
      width: 60px;
    }
  }
}

p-dialog .aevent-dialog {
  height: 50%;
  width: 30%;
}

p-dialog .event-dialog {
  height: 85%;
  width: 60%;

  @media screen and (min-width: 360px) and (max-width: 640px) {
    height: 100%;
    width: auto;
  }
}

.version-class {
  display: block;
  font-size: 11px;
  margin-top: 20px;
  padding-left: 20px;
}

@media screen and (min-width: 360px) and (max-width: 767px) {
  .assign-asset-dialog {
    height: 63%;
    width: 80%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .assign-asset-dialog {
    height: 40%;
    width: 60%;
  }
}

.p-dialog-resizable {
  .p-dialog-content {
    height: 100%;
    // overflow-y: hidden;
  }
}

.access-checkbox-div {
  display: inline-block;
  padding: 5px;
}

.access-editbtn-div {
  display: inline-block;
  padding: 5px;
}

.department-autocomplete {
  margin-top: 4%;

  .ui-inputtext {
    input {
      &[type='text'] {
        border: 0 none;
      }
    }
  }

  .ui-state-default {
    padding: 10px;
  }

  .ui-autocomplete-panel {
    width: 96%;
  }

  ul {
    width: 96%;

    li {
      font-size: 14px;
    }
  }
}

.faq-form {
  margin-bottom: 30px;
}

.faq-panel-marin {
  margin-bottom: 2%;

  .content {
    padding-bottom: 2%;
    text-align: justify;
  }

  .footer {
    text-align: right;
  }

  p-header {
    font-size: 14px;
    padding: 0;
  }

  &.ui-panel {
    padding: 0;

    .ui-panel-titlebar {
      background: $secondaryThemeColor;
      border: 0;
      color: $sidebarIconColor;
    }

    .ui-panel-titlebar-icon {
      color: $secondaryThemeColor;
      font-size: 13px;
      margin-top: 5px;

      span {
        margin-top: 3px;
      }
    }

    .ui-panel-content {
      padding: 16px;
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }
}

.term-conditions {
  padding: 45px;

  h3 {
    color: $sideBarHoverBgColor;
    font-size: 17px;
    margin: 0;
  }
}

.ql-align-justify {
  color: $secondaryThemeColor;
  font-size: 15px;
  margin: 10px 0 45px;
  text-align: justify;
}

.trip-details {
  .pickup-circle {
    color: $mapPickup;
  }

  .drop-circle {
    color: $mapDrop;
  }

  .one-line-block {
    float: right;
    margin-left: 20px;
  }

  .ui-fileupload-buttonbar {
    padding: 0 0 0 10px;
  }
}

.assign-btn {
  float: right;
  margin: 0 15px 9px 0;
}

.attached-docs {
  fieldset {
    margin: 20px 0;
  }

  ul {
    margin: 0;
    padding: 0;

    .panel-div {
      padding: 0 15px;
    }
  }
}

.destination-box {
  background: $destinationBox;
  border-bottom: 1px solid $secondaryThemeColor;
  overflow: auto;
}

.forgot-password-link {
  cursor: pointer;
}

.rental {
  .destination-box {
    background: $destinationBox;
    border-bottom: 1px solid $secondaryThemeColor;
    display: block;
    min-height: 186px;
    overflow: auto;
  }
}

.pricing-bundle {
  min-height: 196px;
}

.fa-info-circle {
  cursor: pointer;
}

.fa-trash {
  cursor: pointer;
}

.fa-pencil {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.at-bottom {
  bottom: 0;
  position: absolute;
}

.assign-search-input-layout {
  margin: 15px 0 10px;
}

.type-of-passenger {
  margin-top: 10px;
}

.driver-eta {
  float: right;
}

.m-20 {
  margin: 20px;
}

.emergency-note {
  color: $defaultThemeColor;
}

.route-map-height {
  height: 446px;
}

.disabled-row {
  background: $stateDefaultBorderColor;
}

.enabled-row {
  background: $white;
}

.col-nopad {
  padding: 0;
}

.sprite {
  display: inline-block;

  &.dropdown {
    background-image: url('../../assets/images/dropdown.svg');
    background-repeat: no-repeat;
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  &.ui-dropdown {
    background-image: url('../../assets/images/ui-dropdown.svg');
    background-repeat: no-repeat;
    cursor: pointer;
    height: 24px;
    width: 24px;
  }

  &.search-field {
    background-image: url('../../assets/images/search.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px;
    width: 30px;
  }

  &.go {
    background-image: url('../../assets/images/go.svg');
    background-repeat: no-repeat;
    cursor: pointer;
    height: 15px;
    width: 15px;
  }

  &.add {
    background-image: url('../../assets/images/add.svg');
    background-repeat: no-repeat;
    height: 26px;
    width: 26px;
  }

  &.delete {
    background-image: url('../../assets/images/delete.svg');
    background-repeat: no-repeat;
    color: #F00;
    cursor: pointer;
    height: 26px;
    margin-left: 4px;
    width: 26px;
  }

  &.sub {
    background-image: url('../../assets/images/sub.svg');
    background-repeat: no-repeat;
    height: 26px;
    width: 26px;
  }

  &.back {
    background-image: url('../../assets/images/back.svg');
    background-repeat: no-repeat;
    height: 27px;
    width: 34px;
  }

  &.back-btn {
    background-image: url('../../assets/images/back-btn.svg');
    background-repeat: no-repeat;
    height: 19px;
    width: 23px;
  }
}

.mar-top-bottom {
  margin: 10px 0;
}

.pad-zero {
  padding: 0;
}

.mar-pad-0 {
  margin: 0;
  padding: 0;
}

.pad-t-0 {
  padding-top: 0;
}

.pad-l-0 {
  padding-left: 0;
}

.pad-r-0 {
  padding-right: 0;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex {
  display: flex;
}

.align-right {
  text-align: right;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-5 {
  margin-top: 5px;
}

.active-button-div {
  &:active {
    background-color: #E3EAFA;
    border: 1px solid;
  }
}

.forgot-popup {
  width: 500px;
}

.franchise-selection-popup {
  width: 500px;
}

// customer event date-time

.customer-container {
  span {
    &.event-date-time {
      font-size: 13px;

      p-dialog {
        label {
          &.ui-radiobutton-label {
            padding-left: 4px;
          }
        }
      }
    }
  }
}

.entries-summery {
  bottom: 27px;
  left: 5px;
  position: relative;
}

.required-star {
  color: $stateErrorTextColor;
  margin-left: 1px;
}

.pad-t-b-0 {
  padding-bottom: 0;
  padding-top: 0;
}

.refund-dialog {
  .form-field {
    .form-radio {
      label {
        &.ui-radiobutton-label {
          padding-left: 4px;
        }
      }
    }
  }
}

.cc-charge-desc {
  color: #FFF;
  font-family: Montserrat;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin-bottom: 7px;
}

.direct-comment {
  input {
    .direct-campaign-comment {
      width: 95%;
    }
  }
}

.p-dropdown.p-disabled,
.p-multiselect.p-disabled {
  background-color: #EEEEEE96 !important;
  opacity: 1 !important;
}

p-dropdown:has(.p-dropdown.p-disabled) {
  cursor: not-allowed;
  opacity: 1 !important;
}

p-multiselect:has(.p-multiselect.p-disabled) {
  cursor: not-allowed;
  opacity: 1 !important;
}

input[disabled] {
  background-color: #EEEEEE96 !important;
  color: #000 !important;
  cursor: not-allowed;
  opacity: 1 !important;
}


@media screen and (min-width: 768px) {
  .summery-div {
    background-color: #1592E6;
    margin: 0 auto;
    position: relative;
    width: 500px;

    &.product-page {
      padding: 0 16px;
      width: unset;
    }

    .summery {
      border-radius: 4px;
    }
  }

  .customer-box-container {
    .customer-box-inner-container {
      .summery-div {
        background-color: #1592E6;
        margin: 0 auto;
        position: relative;
        width: 662px;

        .width-95 {
          width: 95%;
        }

        &.cart-page {
          width: 350px;
        }

        &.customer-details {
          width: 684px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .pad-r-web {
    padding-right: 2.3em;
  }

  .save-btn {
    &.upper {
      padding-right: 1.1em;
    }
  }
}

.goto-home {
  margin-top: 10px;

  .go-home {
    color: #FFF;
    font-family: Montserrat;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: normal;
    text-decoration: underline;
  }
}

.ui-confirmdialog {
  &.p-dialog {
    .p-dialog-content {
      padding: 1em 1.5em 2em;

      @media screen and (max-width: 767px) {
        padding: 1em .5em 2em;
      }
    }
  }
}

.refund-class {
  color: #007AD9;
  cursor: pointer;
}

.progress {
  animation: filler;
  background-color: #002C80;
  height: 205px;
  width: 135px;
}

.cup-image {
  position: absolute;
  top: -2px;
}

.image-margin {
  margin-left: 32%;
}

.food-extra-mapping {
  .text-field {
    margin: 6px 0;
  }
}

@media screen and (min-width: 371px) and (max-width: 499px) {
  .image-margin {
    margin-left: 22%;
  }
}

.percent-div {
  color: $white;
  font-size: 22px;
  font-weight: 600;
  left: 29px;
  position: relative;
  text-align: center;
  top: 70px;
  width: 104px;
}

.inner-div {
  background-color: #F7D41F;
  height: 163px;
  width: 137px;
}

@-webkit-keyframes filler {
  0% {
    width: 0;
  }
}

@-moz-keyframes filler {
  0% {
    width: 0;
  }
}

@keyframes filler {
  0% {
    width: 0;
  }
}

form {
  .form-field {
    .form-control {
      input &[type='radio'] {
        height: 12px;
      }
    }
  }
}

.pad-top {
  padding-top: 1.3%;
}

// This style for customer side Timeslots date picker position/size

.event-date-outer {
  .event-date {
    input &[type='text'] {
      border-radius: 8px;
      height: 58px;
      margin: 0;
      min-width: 100%;
      padding: 20px 0 20px 20px;
    }
  }

  button {
    padding: 20px 0;
  }
}

// to style tab view

.ui-tabview {
  .ui-tabview-title {
    text-transform: capitalize;
  }
}

p-dialog {
  .p-dialog {
    &.dialog {
      &.map-on-list {
        height: 70%;
        max-height: 90%;
        width: 60%;

        .content {
          height: 90%;
          max-height: 90%;
          width: 100%;

          &.region-map {
            height: 85%;
          }
        }
      }

      @media screen and (min-width: 360px) and (max-width: 767px) {
        &.map-on-list {
          height: 80%;
          max-height: 90%;
          width: 80%;

          .content {
            height: 90%;
            max-height: 90%;
            width: 100%;
          }
        }
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        &.map-on-list {
          height: 65%;
          max-height: 90%;
          width: 80%;

          .content {
            height: 90%;
            max-height: 90%;
            width: 100%;
          }
        }
      }
    }
  }
}

.map-on-list {
  height: 70%;
  max-height: 90%;
  width: 60%;

  .p-dialog-header {
    background: #3D4246;
    border: 0;
    color: #FFF;
    font-size: 16px;
    padding: 1em 1.1em;
    position: relative;
  }

  .p-dialog-content {
    border: 0;
    height: 100%;
    overflow: auto;
    overflow-y: auto;
    padding: .5em .75em;
    position: relative;

    #dashboard-heat-map {
      height: 85%;
      max-height: 90%;
      width: 100%;
    }
  }
}

// Style for p-tabView

.ql-container {
  height: 300px;
}

.ql-snow .ql-picker {
  width: 100px;
}

.right-allign {
  display: flex;
  justify-content: flex-end;
}

.right-end-mar {
  left: 19px;
  position: relative;
}

body {
  .assign-asset-dialog {
    &.p-dialog {
      background: $white;
      min-height: 60%;

      &.ui-corner-all {
        border: 0;
      }

      .p-dialog-titlebar {
        background: $secondaryThemeColor;
        border: 0;
        color: $white;
        font-size: 16px;
        padding: 1em 1.1em;
        position: relative;

        .p-dialog-titlebar-close {
          border: 0;
          color: $white;

          &:hover {
            background-color: $secondaryThemeColor;
            border: 0;
            color: $white;
          }
        }
      }

      .page-buttons button {
        min-height: 40px;
      }

      .p-dialog-footer {
        overflow: hidden;
        padding: .8em;

        button {
          width: 100px;
        }
      }
    }
  }

  .assign-asset {
    .p-dialog {
      background: #FFF;
      height: 80%;
      min-height: 65%;
      position: absolute;
      top: 10%;

      .p-dialog-content {
        .page-buttons {
          bottom: 10px;
          position: absolute;
          right: 3%;
        }
      }
    }
  }
}

.phone-with-country-code {
  display: flex;
  justify-content: space-between;
}

.event-icon {
  background-image: url('/assets/images/new_icons/events_icon_new.png');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  bottom: 2px;
  min-height: 20px;
  min-width: 20px;
  position: relative;
  right: 3px;
}

.margin-top {
  margin-top: 1.3em;
}

.note-checkbox {
  .ui-chkbox-label {
    font-size: 12px;
    margin-left: 11px;
  }
}

.form-item .form-field {
  .recipient {
    .ui-inputtext {
      left: -2px;
      margin: 0 !important;
      padding: 5px !important;
      position: absolute;
      top: -1px;
    }
  }
}

.event-overlay {
  // &.p-overlaypanel {
  //   // position: absolute;
  //   // right: 9%;
  //   // top: 98%;
  //   // z-index: 999;
  // }

  .pointer {
    cursor: pointer;

    &:hover {
      // background: #F2EDED;
      color: $defaultThemeColor;
    }
  }
}

.popup-overlay {
  position: relative;

  .event-overlay {
    &.p-overlaypanel {
      position: absolute;
      right: 3%;
      top: 75%;
    }
  }
}

.staff-overlay {

  left: 18% !important;
  position: absolute;
  top: 78% !important;
  width: 60% !important;
}

p-overlaypanel .staff-overlay {
  &:before {
    content: none;
  }

  &:after {
    content: none;
  }

  margin-top: 0;
}

// staff pannel form start
// staff pannel form end
.rowHeaderTd {
  padding: 5px 10px !important;
}

.rowHeader {
  color: #568EE8;
  font-weight: 600;
  text-transform: uppercase;
}

.graph-no-data {
  align-items: center;
  display: flex;
  font-size: 17px;
  font-weight: 600;
  justify-content: center;
  min-height: 11em;
}

.form-control {
  .p-dropdown {
    display: flex;
  }
}

.p-component {
  font-family: unset;
  font-size: unset;
}

.p-dropdown .p-dropdown-label {
  font-family: $fontFamily !important;
  font-size: $fontSize !important;
  padding: 10px !important;
  vertical-align: middle !important;
}

.p-highlight {
  .p-checkbox {
    .p-checkbox-box {
      border-color: #A6A6A6;
    }
  }
}

@import './mobiscroll-style';

.mbsc-ios.mbsc-timeline-header-date-text.mbsc-timeline-header-text {
  display: none;
}

.p-panelmenu .p-panelmenu-header>a .p-menuitem-icon {
  padding-right: 1.6rem;
}


li.p-ripple.p-element.p-multiselect-item.p-disabled {
  background: rgba(102, 102, 102, .3);
}

.p-toast {
  position: fixed;
  width: 25rem;

  @media screen and (min-width: 360px) and (max-width: 767px) {
    width: 21rem !important;
  }
}

.width-menu {
  width: 100%;
}

.p-picklist-list-wrapper .p-picklist-source-wrapper {
  height: 14em;
  overflow-y: auto;
}

.p-picklist-list-wrapper .p-picklist-target-wrapper {
  height: 14em;
  overflow-y: auto;
}

.no-records {
  align-items: center;
  color: #E04848;
  display: flex;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  height: 25px;
  justify-content: center;
  margin: .5em;
  text-align: center;
  width: 100%;
}

.align-button {
  margin-top: 24.5px;

}

.elements i {
  margin: -21px -41px;
}

@media screen and (min-width: 360px) and (max-width: 774px) {
  .changePasswordBtn {
    width: 100%;

    p-button {
      width: 100%;

      .p-button {
        width: 100%;
      }
    }
  }
}

.medium-btn {
  &.assign-btn {
    width: 5em;
  }
}

.hide {
  display: none;
}

.red-text {
  color: red;
  margin-top: 1em;
}

.abc div {
  width: 850px;
}

::ng-deep .eventList {
  .event-div {
    display: flex !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    .circle-class {
      margin-top: 2px !important;
    }

    .event-text {
      text-align: left !important;
      padding-left: 5px !important;
    }
  }
}

.camelCase {
  text-transform: capitalize;
}

.p-colorpicker-panel .p-colorpicker-hue {
  background: transparent url('/assets/images/action_icons/hue.png') no-repeat left top !important;
}

.automation-hide {
  display: none;
}