// Show priority 3 at 640px (40em x 16px)
@media screen and (min-width: 768px) and (max-width: 1023px) {
  body {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;

    .tablet-width {
      padding-top: 27px;
    }

    .header-strip {
      left: 191px;
    }

    .main-container {

      .main-content {
        padding: 7px;
      }
    }

    .p-button {
      &.p-button-text-icon-left {
        .p-button-text {
          padding: .429em 1em .429em 2.5em;
        }
      }
    }

    .menu-image {
      &.p-dialog {
        .p-dialog-content {
          width: 500px;
        }
      }
    }
  }

  .page-layout {
    .form-container {
      .form-field {
        input {
          &[type='text'] {
            font-size: 13px;
            height: 33px;
            margin: 3px 0;

            &.search {
              padding-left: 25px;
            }

            &.prefix-pad-dollar {
              padding-left: 15px;
              padding-right: 4px;
            }
          }
        }

        .form-label {
          font-size: 13px;
          font-weight: 700;
        }

        .p-dropdown {
          font-size: 13px;
          height: 30px;
          margin: 3px 0;
        }
      }

      .image-upload {
        height: auto;
        width: 44%;
      }
    }

    label {
      &.search-icon {
        left: 5px;
        position: absolute;
        top: 4px;
      }

      &.dollar-icon {
        left: 14px;
        position: absolute;
        top: 13px;
      }

      &.percent-icon {
        top: 5px;
      }
    }

    .menu-offered {
      .single-item {
        border-bottom: 1px solid $grey;
        margin-bottom: 5px;
        margin-top: 5px;

        .mob-disp-flx {
          display: flex;
        }

        .top-pad {
          padding-top: 1.5rem;
        }
      }
    }

    .search-and-export-data-panel {
      .export-button {
        bottom: -58px;
        left: 19px;
        position: relative;
      }
    }

    .display-image {
      .p-fileupload {
        position: relative;
        right: 20px;

        .p-fileupload-buttonbar {
          width: 58%;

          .p-button {
            &.p-button-text-icon-left {
              .p-button-text {
                padding: .429em 1em .429em 2em;
              }
            }
          }
        }
      }
    }

    .anchor-btn {
      a {
        &.p-button {
          width: 30%;

          &.upper {
            width: 48%;
          }
        }
      }
    }
  }

  .filter-container {
    .p-calendar-button {
      &.p-button {
        &.p-state-default {
          height: 22px;
        }
      }
    }

    .profile-btn {
      .p-button {
        &.p-state-default {
          margin: 5px 1px 5px 5px;
        }
      }
    }
  }

  .action-width {
    width: 150px;
  }
}
