// @import '../node_modules/primeng/resources/themes/nova-light/theme.css';
@import '../node_modules/primeng/resources/themes/nova/theme.css';
// @import '../node_modules/primeng/resources/themes/rhea/theme.css';
@import 'theme';
@import '../node_modules/primeicons/primeicons.css';
@import 'common';
@import './app/styles/common.scss';
@import 'forms';
@import 'variables';

@import 'shared/components/header.component';
// @import './app/modules/shared/components/customer-header/customer-header.component';
// @import './app/modules/shared/components/customer-footer/customer-footer.component';
// @import './app/modules/customer/pages/customer-details/customer-details.component';
// @import './app/modules/customer/pages/search/search.component';
@import 'shared/components/page-header.component';
@import 'shared/components/sidebar.component';
@import 'shared/directives/search-location.directive';
@import 'shared/components/mi-grid.component';


@import 'app/pages/sign-in.component';
@import 'shared/components/franchise';
@import 'shared/components/admin';
@import 'shared/components/franchise-responsive';
@import 'shared/components/franchise-responsive-tablet';
@import 'app/pages/feanchise-sign-in.component';

// only Admin style
@import 'admin/admin-style';


// only Staff style
@import 'staff/staff-style';

@import '@mobiscroll/angular/dist/css/mobiscroll.scss';

@import '@mobiscroll/angular/dist/css/mobiscroll.scss';
