// Colors
$white: #FFF;
$black: #000;
$defaultThemeColor: #29ABE2;
$secondaryThemeColor: #3D4246;
$inputGroupTextColor: #000;

$btnBgColor1: #95AD6F;
$btnBgColor2: #315E08;
$btnBgColor3: #003;
$btnBgColor: #315E08;
$themeColor: #360;

$bodyBgColor: #F5F6FA;

$mainContentBgColor: #F6F6F6;
$topBarBgColor: $white;
$topBarItemHoverBgColor: #F1F1F1;
$BodyTextColor: #454545;

$linkColor: #666161;
$topBarTextColor: #303030;

$sidebarBgColor: #FFF; // $defaultThemeColor;
$sidebarMenuTextColor: rgba(255, 255, 255, .6);
$sideBarHoverBgColor: #EBEFFF; //#0C1224;
$sidebarMenuHoverTextColor: $white;
$sidebarIconColor: #B0BEC5;
$sidebarActiveColor: #F7941D;

$signInBgColor: #009688;
$headerFontSize: 24px;
$headerSplitBarColor: #CBD1DC;
$headerUserName: #101010;

$buttonDefaultTextColor: $white;
$buttonDefaultBgColor: #06A0FF; //$defaultThemeColor;
$negativeButtonColor: $secondaryThemeColor;
$buttonHoverColor: #29ABE2;
$negativeButtonHoverColor: #24282A;

$pageHeaderBgColor: transparent;
$panelDivBgColor: $white;

// Forms

$formFieldTopBottomMargin: 8px;
$formFieldLeftRightMargin: 0;
$inputFieldsBgColor: $white;
$inputFieldBorderColor: EEEEEE7;
$inputTextHeight: 30px;
$inputFieldBorderColor: #E1E3E7;
$inputTextHeight: 33px;
$franchiseInputTextHeight: 40px;
$readOnlyFieldColor: #DEDEDE;
$activeBoxTextColor: #636363;
$inputBorderColor: EEEEEE7;

// Pring NG

$fontFamily: 'Montserrat', sans-serif;
$fontSize: 14px;
$borderRadius: 2px;
$disabledOpacity: .35;
$calendarIconColor: #3D3D3D;
$dropdownBgColor: #1A1A1A;
$dropdownBorderColor: #C5C0CB;
$selectButtonBorderColor: #EEE;

// Header
$headerBorderWidth: 1px;
$headerBorderColor: $white;
$headerBgColor: $white;
$headerTextColor: #444;
$headerFontWeight: bold;
$headerIconTextColor: #444;

// Content
$contentBorderWidth: 1px;
$contentBorderColor: #DDD;
$contentBgColor: $white;
$contentTextColor: #444;

// Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: #DDD;
$stateDefaultBgColor: #F6F6F6;
$stateDefaultTextColor: #0073EA;

// Active State
$stateActiveBorderColor: #DDD;
$stateActiveBgColor: $white;
$stateActiveTextColor: $white;

// Highlight State
$stateHighlightBorderColor: #9158D9;
$stateHighlightBgColor: #9158D9;
$stateHighlightTextColor: $white;

// Focus State
$stateFocusBorderColor: #0073EA;
$stateFocusBgColor: #0073EA;
$stateFocusTextColor: $white;

// Error State
$stateErrorBorderColor: #DDD;
$stateErrorBgColor: $white;
$stateErrorTextColor: #AB0F0F;

// Hover State
$stateHoverBorderColor: #0073EA;
$stateHoverBgColor: #0073EA;
$stateHoverTextColor: $white;

// Forms
$inputBgColor: $white;
$inputTextColor: #444;
$invalidInputBorderColor: #FF0084;

// Glow messages
$growlMessageErrorColor: #F44336;
$growlMessageSuccessColor: #4CAF50;

// Grid
$gridHeaderTextColor: #1E1E1E;
$gridHeaderBgColor: #EDEDED;
$gridHeaderBorderColor: $topBarBgColor;
$gridBtnColorDanger: #D9534F;
$gridBtnColorDangerFocus: #C9302C;
$gridBtnColorSuccess: #5CB85C;
$gridBtnColorSuccessFocus: #449D44;
$loaderBgColor: transperent;
$actionButtonDeactivate: #1E1E1E;
$gridSelectedRowHover: #D9DBDD;
$gridFileHoverColor: #4CAF50;
$borderRightColor: #DDD;

$color_log_cabin_approx: #222;
$color_boulder_40_approx: rgba(120, 120, 120, .4);
$color_fuscous_gray_40_approx: rgba(80, 80, 80, .4);
$color_shark_40_approx: rgba(40, 40, 40, .4);
$black_40: rgba(0, 0, 0, .4);
$color_japanese_laurel_approx: #006400;
$color_red_berry_approx: #8B0000;
$color_surf_crest_approx: #CEC;
$color_quill_gray_approx: #D3D3D3;
$color_pumice_approx: #C7C7C7;
$color_danube_approx: #5B9BD5;
$color_silver_approx: #BEBEBE;
$color_black_haze_approx: #F6F6F6;
$color_gallery_approx: #F0F0F0;
$white_50: rgba(255, 255, 255, .5);
$black: #000;
$gray: #808080;

$gridOddRowColor: $white;
$gridRowSelectedColor: #EEEAEA;
$gridSelectorHighliterColor: #EDEDED;
$paginationTextColor: #3E3E3E;

// Checkbox
$checkboxBackgroundColor: $white;
$checkboxBorderColor: #EDEDED;
$checkboxActiveBorderColor: #E1E3E7;
$checkboxCheckColor: $defaultThemeColor;

// Table
$tableHeaderText: rgba(30, 30, 30, .5);

// google map realate color
$mapPickup: #4DA03A;
$mapDrop: #EC1D25;

$destinationBox: #E9E9EC;
$activeStatus: #325E07;
$inactiveStatus: #901E1E;
$endedStatus: #F76108;

$fontColor: #757575;
$tabActiveTextColor: $activeStatus;
$fontNormal: normal;
$gridIconColor: #7D7D7D;
$gridRowBgColor: #DEDEDE;
