@import '../../admin/admin-theme.scss';

body {
  .sidebar {
    .p-panelmenu {
      .p-panelmenu-header {
        a {
          background-color: transparent;
          border: 0;
          color: $sidebarColor;
          font-weight: 500;
          padding: 20px 0 15px 18px;
          white-space: nowrap;

          &.p-menuitem-link-active {
            border: 0;
            border-right: 4px solid $sidebarActiveColor;
            color: $sidebarActiveColor;
            font-weight: 700;
          }

          &:hover {
            background-color: $sidebarHoverColor;
            border: 0;
            color: $sidebarActiveColor;
            font-weight: 700;
          }
        }

        &.p-state-active {
          a {
            background-color: transparent;
          }
        }

        &.p-highlight {
          &+.p-toggleable-content {
            .p-submenu-list {
              .p-menuitem-link-active {
                border: 0;
                border-right: 4px solid $sidebarActiveColor;
                color: $sidebarActiveColor;
                font-weight: 700;

                .p-menuitem-text {
                  color: $sidebarActiveColor;
                }
              }
            }
          }
        }
      }

      &.p-state-active {
        a {
          background-color: transparent;
        }
      }
    }
  }

  .pi {
    font-size: 1rem;
  }

  .p-button {
    &.p-button-text-icon-left {
      .p-button-text {
        font-size: 1em;
        margin-top: 2%;
        padding: .429em 1em .429em 2.5em;
      }
    }
  }

  .import-file-button {
    .p-fileupload {
      .p-fileupload-buttonbar {
        background-color: #FFF;
        border: 0;
        border-bottom: 0;
        color: #333;
        margin: 0 .5rem;
        padding: 0;

        .p-button-icon-left {
          display: none;
        }

        .p-button-text {
          padding-left: 1em;
        }
      }

      .p-fileupload-content {
        border: unset;
      }
    }
  }

  .datetime-picker {
    .p-datepicker {
      table {
        td {
          padding: .3em;
        }
      }
    }
  }

  .p-datepicker {
    padding: .257rem;
    // z-index: 1001 !important;
    // transform-origin: center top !important;
    // top: 0 !important;
    // left: 0 !important;

    .p-datepicker-group {
      background-color: $white;

      table {
        margin: 0;
      }
    }

    table {
      td {
        padding: .3em;

        span {
          // margin: 0;
          height: 2rem;
          padding: 0;
          width: 2rem;
        }

        a {
          padding: 0;
        }
      }
    }

    .p-timepicker {
      padding: 5px;

      span {
        font-size: 1rem;
      }
    }
  }

  .calendar-btn-container {
    .p-datepicker {
      left: 0 !important;
      min-width: 310px !important;
      padding: .257rem;
      top: 0 !important;
      transform-origin: center top !important;
      z-index: 1001 !important;
  }
}

  .p-table {
    .p-paginator-bottom {
      background-color: transparent;
      border-left: 0;
      border-right: 0;

      .p-dropdown {
        margin-bottom: 0;
        margin-top: 0;
        position: relative;
        right: 550px;
        width: 3em;
      }
    }
  }
}

.tablet-width {
  padding-top: 27px;
}

// body

.page-layout {
  background-color: $white;
  border-radius: 5px;
  overflow: unset;
  padding: 2% 1%;

  p-checkbox {
    label {
      margin-left: 8px;
    }
  }

  .p-panel {
    .p-panel-content {
      border: unset;
    }

    .p-panel-titlebar {
      background-color: $white;
      border: unset;
      color: #283142;
    }
  }

  .p-table {
    .p-table-tbody {
      tr {
        &:nth-child(2n) {
          &.p-state-highlight {
            background-color: $color9;
            color: $white;
          }
        }

        &.p-state-highlight {
          background-color: $white;
          color: $white;
        }
      }
    }

    .p-table-thead {
      tr {
        th {
          background: $white;
          border: unset;
        }
      }
    }

    .p-table-tfoot {
      tr {
        td {
          border: unset;
        }
      }
    }
  }

  .p-dropdown {
    .p-dropdown-label {
      padding: 5px 8px;
    }
  }

  .form-container {
    .form-field {
      .form-label {
        color: $labelColor;
        font-family: $fontFamily;
        font-size: 14px;
        font-weight: 700;
        opacity: .8;
      }

      textarea {
        border: 1px solid $inputFieldBorderColor;
        font-family: $fontFamily;
        font-size: 14px;
        height: $franchiseInputTextHeight + 100;
        margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
        padding: 1% 2%;
        width: 96%;
      }

      input {
        &[type='text'] {
          border: 1px solid $inputFieldBorderColor;
          border-radius: 5px;
          font-family: $fontFamily;
          font-size: 14px;
          height: $franchiseInputTextHeight;
          margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
          padding: 2%;
          width: 100%;

          &.quarter-ui {
            width: 92%;
          }
        }

        &[type='tel'] {
          border: 1px solid $inputFieldBorderColor;
          border-radius: 5px;
          font-family: $fontFamily;
          font-size: 14px;
          height: $franchiseInputTextHeight;
          margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
          padding: 2%;
          width: 100%;

          &.quarter-ui {
            width: 92%;
          }
        }

        &[type='password'] {
          border: 1px solid $inputFieldBorderColor;
          border-radius: 5px;
          height: $franchiseInputTextHeight;
          margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
          padding: 0 2%;
          width: 96%;
        }

        &:read-only {
          background: $readOnlyFieldColor;
        }

        &.full-width {
          vertical-align: middle;
          width: 100%;

          &:disabled {
            background: rgba(239, 239, 239, .3);
          }
        }

        &::placeholder {
          margin: 6px 0;
          opacity: .5;
          padding-top: 15px;
          text-transform: unset;
        }

        &.search {
          padding-left: 25px;
        }

        &.prefix-pad {
          padding-left: 25px;
          padding-right: 17px;
        }
      }

      .p-dropdown {
        background: $white;
        border: 1px solid $color2;
        border-radius: 5px;
        height: 33px;
        margin: 7px 0;
        min-width: 100%;
        padding: 16px;

        .p-dropdown-label {
          //margin-top: -5px;
          padding: 0;
        }
      }

      .p-state-disabled {
        background: rgba(239, 239, 239, .3);
        border: 1px solid #DDD;
        color: #1A2D3E;
        cursor: not-allowed !important;
        opacity: 1;

        .p-dropdown-trigger {
          opacity: .5;
        }
      }

      .p-widget {
        &:disabled {
          background: #ACB4D6;
          border: 1px solid #ACB4D6;
          cursor: not-allowed;
          opacity: .8;
        }
      }
    }

    .pad-t-b-0 {
      padding-bottom: 0;
      padding-top: 0;
    }

    .form-errors {
      padding: 0;
    }

    .image-upload {
      height: auto;
      width: 27%;
    }
  }

  .negative-button1 {
    margin-right: .5em;
  }

  .header {
    color: $labelColor;
    font-size: 22px;
    margin: 0;
  }

  .list-header {
    color: $color3;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
  }

  table {
    padding-left: 9px;
    text-align: left;

    th {
      text-transform: uppercase;
    }

    tr {
      &.row-height {
        border-color: $red;
        height: 90px;
      }
    }

    td {
      text-align: left;

      &.img-cell-width {
        width: 20%;
      }
    }
  }

  .menu-list {
    display: block;

    .web-hide {
      display: none;
    }

    .bottom-border {
      border-top: 2px solid $color4;
    }

    .p-button {
      &.p-state-default {
        margin: 12px;
      }
    }

    .btn-ht {
      height: 35px;
    }

    .cross-icon {
      cursor: pointer;
      margin-top: 19px;
    }

    .each-item {
      &.image {
        .image-div {
          display: flex;
          margin-left: 15px;
          padding: 6%;
          position: relative;
        }
      }
    }

    .menu-image-list {
      vertical-align: middle;
      width: 50%;

      .menu-edit-icon {
        bottom: 10%;
        cursor: pointer;
        left: 65%;
        position: absolute;
        zoom: 1.2;

        @media screen and (max-width: 800px) {
          bottom: 10%;
          left: 45%;
        }
      }
    }

    .action-btns {
      padding-top: 15px;

      .edit-btns-div {
        display: flex;
      }
    }
  }

  .food-extra-mapping {
    .action-btns {
      padding-top: 20px;
    }
  }

  .mobile-hide {
    display: flex;
  }

  .disp-flex {
    display: flex;

    &.each-item {
      flex-grow: 1;
    }
  }

  .disp-web-flex {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .food-extra-mapping {
    .disp-web-flex {
      align-items: inherit;
    }
  }

  .pad-left-9 {
    padding-left: 9px;
  }

  .details-container {
    font-size: 15px;
    margin-top: 10px;
    padding-left: 9px;

    .label {
      color: $labelColor;
      font-family: $fontFamily;
      font-size: 14px;
      font-weight: 600;
      opacity: .6;
    }

    .value {
      color: $color5;
      font-family: $fontFamily;
      font-size: 16px;
      font-weight: 500;
      word-break: break-all;
    }

    .row-height {
      height: 50px;

      .item-name {
        width: 38%;
        word-break: break-all;

        &.food-extra {
          padding-left: 2%;
        }
      }

      .top-border {
        border-top: 1px solid $black;
      }
    }

    table {
      padding: 0;

      tr {
        td {
          border-bottom: 1px solid $color4;
          color: $color3;
          font-size: 15px;

          &.total {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }

    .label-header {
      font-weight: bold;
      text-transform: uppercase;
    }

    .item-seperator {
      border-bottom: 1px solid rgb(244, 245, 250);
      margin-bottom: 10px;
    }

    .padding-top-bottom {
      padding: 10px 1em 10px 0;
    }

    .food-total-item {
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
      margin: 10px 0;
    }

    .margin-zero {
      margin: 0;
    }
  }

  label {
    &.search-icon {
      left: 5px;
      opacity: .5;
      position: absolute;
      top: 10px;
    }

    &.dollar-icon {
      left: 14px;
      opacity: .8;
      position: absolute;
      top: 19px;
    }

    &.percent-icon {
      left: 10px;
      opacity: .8;
      position: absolute;
      top: 10px;
    }
  }

  .filter-container {
    .p-dropdown-panel {
      .p-dropdown-list {
        background: $white;
      }
    }

    p-dropdown {
      .p-dropdown-items {
        .p-state-highlight {
          background: $color11;
        }
      }
    }

    // sass-lint:disable force-pseudo-nesting

    .form-field {
      .p-dropdown-panel {
        .p-dropdown-item:not(.p-state-highlight):hover {
          background-color: $color12;
        }
      }
    }

    // sass-lint:enable force-pseudo-nesting

    .p-calendar {
      .p-datepicker {
        background: $white;
      }
    }

    .p-button {
      &.p-state-default {
        margin: 7px 1px 7px 7px;
      }
    }

    .map-region-btn {
      &.p-button {
        &.p-state-default {
          height: 35px;
          margin-left: 0;
          margin-top: 18px;
        }
      }

      .map-region-icon {
        margin: 4px 0 6px 10px;
        width: 15%;
      }
    }
  }

  .l-pad-17 {
    padding-left: 17px;
  }

  .marg-t-0 {
    margin-top: 0;
  }

  .pad-zero {
    padding: 0;
  }

  .p-datepicker {
    table {
      td {
        padding: 0;

        a {
          padding: 4px;
        }
      }
    }

    .p-timepicker {
      padding: 0;
    }
  }

  .menu-offered {
    .web-header {
      border-bottom: 2px solid $color4;
      font-weight: 700;
      justify-content: space-between;
      text-transform: uppercase;
    }

    .single-item {
      border-bottom: 1px solid $color4;
      margin-bottom: 0;
      margin-top: 0;

      .top-pad {
        padding-top: 2%;
      }

      input {
        &[type='text'] {
          &.offered-price {
            margin: 0;
            padding-left: 25px;
            width: 50%;
          }
        }
      }

      .offered-price-sign {
        opacity: .6;
        position: relative;
        right: 47%;
      }

      .menu-offered-img-width {
        width: 21%;
      }
    }

    .menu-title {
      display: none;

      &+div {
        position: relative;
      }
    }

    .assign-staff {
      border-bottom: 1px solid #979797;

      .name {
        color: #444;
        font-size: 16px;
        font-weight: bold;
      }

      .email {
        color: #568EE8;
        font-size: 13px;
        font-weight: 500;
        word-break: break-word;
      }

      .schedule-section {
        align-items: center;
        display: flex;
        font-size: 15px;
        font-weight: 500;

        .event-block {
          margin-bottom: 3%;
          padding-bottom: 3%;
        }

        .event-border {
          border-bottom: 1px solid #979797;

          &:last-child {
            border-bottom: 0;
          }
        }
      }

      .checkbox-container .p-chkbox .p-chkbox-box {
        height: 22px;
        width: 25px;
      }
    }
  }

  .event-link-div {
    display: inline-flex;

    .link-label {
      margin-left: 7px;
    }

    .link-text {
      margin-left: 12px;
      padding-top: 1px;
      word-break: break-all;
    }

    @media (min-width: 360px) and (max-width: 460px) {
      padding: 0 .5rem;
    }
  }

  .qr-code-container {
    display: flex;
    justify-content: flex-end;

    @media (min-width: 1200px) {
      padding-left: 1rem;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      display: flex;
      justify-content: flex-end;
    }

    @media (min-width: 360px) and (max-width: 460px) {
      justify-content: flex-start;
      padding-left: 1rem;
    }
  }

  // common start

  .padding-1per {
    padding: 1%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .back-arrow-div {
      padding: 5px 0 0 11px;
    }
  }

  .pad-t-b-0 {
    padding-bottom: 0;
    padding-top: 0;
  }

  .display-image {
    .p-fileupload {
      position: relative;
      right: 37%;
    }
  }

  .position-rel {
    position: relative;
  }
}

//sidebar
// Sidebar end

.p-confirmdialog {
  background: $white;
  color: $black;
}

.cond-color {
  &.blue {
    p-dropdown {
      .p-dropdown {
        border: solid .5px $blueDropdown;

        .p-dropdown-label {
          color: $blueDropdown;
        }

        .p-dropdown-trigger {
          color: $blueDropdown;
        }
      }
    }
  }

  &.confirmed {
    p-dropdown {
      .p-dropdown {
        border: solid .5px #079992;

        .p-dropdown-label {
          color: #079992;
        }

        .p-dropdown-trigger {
          color: #079992;
        }
      }
    }
  }

  &.yellow {
    p-dropdown {
      .p-dropdown {
        border: solid .5px $yellowDropdown;

        .p-dropdown-label {
          color: $yellowDropdown;
        }

        .p-dropdown-trigger {
          color: $yellowDropdown;
        }
      }
    }
  }

  &.green {
    p-dropdown {
      .p-dropdown {
        border: solid .5px $greenDropdown;

        .p-dropdown-label {
          color: $greenDropdown;
        }

        .p-dropdown-trigger {
          color: $greenDropdown;
        }
      }
    }
  }

  &.red {
    p-dropdown {
      .p-dropdown {
        border: solid .5px $redDropdown;

        .p-dropdown-label {
          color: $redDropdown;
        }

        .p-dropdown-trigger {
          color: $redDropdown;
        }
      }
    }
  }
}

.back-arrow-icon {
  background-image: url('/assets/images/group-2.png');
  background-position: -171px -58px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 12px;
  width: 30px;
  zoom: 1;
}

.view-details-icon {
  background-image: url('/assets/images/group-2.png');
  background-position: -113px -2px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 12px;
  width: 30px;
  zoom: 1;
}

.hide-password-icon {
  background-image: url('/assets/images/group-2.png');
  background-position: -5px 0;
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  width: 30px;
  zoom: 1;
}

.show-password-icon {
  background-image: url('/assets/images/group-2.png');
  background-position: -59px 0;
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  width: 30px;
  zoom: 1;
}

.save-icon {
  background-image: url('/assets/images/admin-icons.png');
  background-position: -195px -249px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 5px;
  width: 30px;
  zoom: 1;
}

.deactive-icon {
  background-image: url('/assets/images/admin-icons.png');
  background-position: -32px -194px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 5px;
  width: 30px;
  zoom: 1;
}

.permission-icon {
  background-image: url('/assets/images/manage_staff_icon.png');
  background-position: 5px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 14px;
  width: 30px;
  zoom: .95;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.active-icon {
  background-image: url('/assets/images/admin-icons.png');
  background-position: -86px -194px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 5px;
  width: 30px;
  zoom: 1;
}

.cancel-icon-menu {
  background-image: url('/assets/images/admin-icons.png');
  background-position: -250px -250px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 5px;
  width: 30px;
  zoom: 1.1;
}

.manage-icon-menu {
  color: #29ABE2;
  cursor: pointer;
  font-size: 21px;
  padding-left: 3px;
  padding-top: 4px;
}

.orders-icon {
  background-image: url('/assets/images/group-2.png');
  background-position: -276px -53px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 15px;
  width: 30px;
  zoom: 1;
}

.clone-icon {
  color: #06A0FF;
  margin-top: 10px;
  zoom: 1.4;
}

.event-hold-icon {
  background-image: url('/assets/images/admin-icons.png');
  background-position: -32px -194px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 14px;
  width: 30px;
  zoom: 1;
}

.event-open-icon {
  background-image: url('/assets/images/admin-icons.png');
  background-position: -86px -194px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 14px;
  width: 30px;
  zoom: 1;
}

.event-order-report-excel {
  background-image: url('/assets/images/admin-icons.png');
  background-position: -305px -249px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 14px;
  width: 30px;
  zoom: 1;
}

.event-clone {
  background-image: url('/assets/images/admin-icons.png');
  background-position: -34px -309px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 35px;
  margin-top: 14px;
  width: 30px;
  zoom: 1;
}

.event-restore {
  background-image: url('/assets/images/restore.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 35px;
  margin-left: 3px;
  margin-top: 8px;
  width: 25px;
  zoom: 1;
}

.settings {
  background-image: url('/assets/images/settings.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 20px;
  margin-top: 13px;
  width: 20px;
  zoom: 1;

  @media (min-width: 340px) and (max-width: 460px) {
    margin-top: 0;
  }
}

@media (min-width: 768px) and (max-width: 768px) {

  .edit-pencil-icon,
  .event-clone,
  .event-hold-icon,
  .event-order-report-excel,
  .orders-icon {
    width: 35px;
  }
}

.sidebar-icon {
  background-repeat: no-repeat;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.sidebar-franchise-icon {
  background-image: url('/assets/images/new_icons/franchise_icon.png');
}

.sidebar-dashboard-icon {
  background-image: url('/assets/images/new_icons/sidebar_dashboard_icon.png');
}

.sidebar-clock-icon {
  background-image: url('/assets/images/new_icons/sidebar_start_timer_icon.png');
}

.sidebar-availabity-icon {
  background-image: url('/assets/images/new_icons/availability_icon.png');
}

.sidebar-reports-icon {
  background-image: url('/assets/images/new_icons/reports_icon.png');
}

.sidebar-customer-icon {
  background-image: url('/assets/images/new_icons/customer_icon.png');
}

.sidebar-home-icon {
  background-image: url('/assets/images/new_icons/sidebar_dashboard_icon.png');
}

.sidebar-timer-icon {
  background-image: url('/assets/images/new_icons/sidebar_start_timer_icon.png');
}

.sidebar-clients-icon {
  background-image: url('/assets/images/new_icons/clients_icon.png');
}

.sidebar-invoice-icon {
  background-image: url('/assets/images/new_icons/invoice_icon.png');
}

.sidebar-event-icon {
  background-image: url('/assets/images/new_icons/events_icon_new.png');
  background-size: 20px 20px;
}

.sidebar-no-reply-icon {
  background-image: url('/assets/images/new_icons/no_reply.png');
  background-size: 20px 20px;
}

.sidebar-assets-icon {
  background-image: url('/assets/images/new_icons/assets_icon.png');
}

.sidebar-task-icon {
  background-image: url('/assets/images/new_icons/task_icon.png');
  background-size: 20px 20px;
}

.sidebar-staff-icon {
  background-image: url('/assets/images/new_icons/staff_icon.png');
}

.sidebar-menu-icon {
  background-image: url('/assets/images/new_icons/menu_icon.png');
}

.sub-menu-food-extra {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/food_icon.png');
}

.sub-menu-menu {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/menus_icon.png');
}

.sub-menu-order {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/order_icon.png');
  background-position: 0 3px;
}

.sub-menu-payout {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/payout_icon.png');
  background-position: 0 3px;
}

.sidebar-settings-icon {
  background-image: url('/assets/images/new_icons/settings_icon.png');
}

.sub-menu-orders-reports {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/order_icon.png');
}

.sub-menu-payout-reports {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/payout_icon.png');
}

.sub-menu-staff {
  background: no-repeat;
  background-image: url('/assets/images/action_icons/clone.png');
}

.sub-menu-schedule {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/events_icon.png');
}

.sidebar-settings-icon {
  background-image: url('/assets/images/new_icons/settings_icon.png');
}

.sidebar-dashboard-icon {
  background: no-repeat;
  background-image: url('/assets/images/dashboard/home.png');
}

.sub-menu-give-back {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/give_back.png');
  background-position: 0 3px;
}

.sub-menu-sales {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/sales.png');
  background-position: 0 3px;
}

.sub-menu-sales-history {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/sales_history.png');
  background-position: 0 3px;
}

.sub-menu-report-staff {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/employee_staff.png');
  background-position: 0 3px;
}

.sub-menu-staffreport {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/staff_report.png');
  background-position: 0 3px;
}

.sub-menu-report-equipment {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/equipment_report.png');
  background-position: 0 3px;
}

.sub-menu-report-clientranking {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/client_ranking.png');
  background-position: 0 3px;
}

.sub-menu-report-rebook {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/calendar_rebook.png');
  background-position: 0 3px;
}

.manage-template-menu {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/manage_template_icon.png');
  background-position: 0 3px;
}

.manage-category-menu {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/manage_category_icon.png');
  background-position: 0 3px;
}

.menu-items-menu {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/menu_items_icon.png');
  background-position: 0 3px;
}

.extra-category-menu {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/extras_category_icon.png');
  background-position: 0 3px;
}

.extra-items-menu {
  background: no-repeat;
  background-image: url('/assets/images/new_icons/extras_items_category.png');
  background-position: 0 3px;
}

.sidebar-marketing-icon {
  background: no-repeat;
  background-image: url('/assets/images/sidebar/marketing_icon.png');
  background-position: 0 3px;
}

.sidebar-campaign-icon {
  background: no-repeat;
  background-image: url('/assets/images/sidebar/campaign_icon.png');
  background-position: 0 3px;
}

.sidebar-contacts-icon {
  background: no-repeat;
  background-image: url('/assets/images/sidebar/legacy_data_icon.png');
  background-position: 0 3px;
}

.anchor-btn {
  .profile-header {
    padding-left: 15px;
  }

  .a-div {
    padding-top: 0;
    text-align: end;
  }

  a {
    &.p-button {
      height: 38px;
      margin-left: 10px;
      width: 15%;

      &.upper {
        width: 30%;
      }
    }

    label {
      position: relative;
      top: 7px;
    }
  }
}

// franchise end
// common media start

@media screen and (max-width: 600px) {
  .main-panel {
    margin-left: 250px;

    .main-container {
      .main-content {
        padding: 7px;
      }
    }

    .p-button {
      &.p-state-default {
        font-size: 13px;
      }
    }

    .mi-grid {
      padding: 15px 5px;

      p-table {
        .p-table {
          .p-table-tbody {
            tr {
              td {
                display: flex;
                font-size: 13px;
                padding: 8px 5px;

                .p-column-title {
                  min-width: 34%;
                  overflow: hidden;
                  text-overflow: unset;
                  white-space: normal;
                  width: 34%;
                }

                .eclipse {
                  div {
                    overflow: hidden;
                    text-overflow: unset;
                    white-space: initial;
                    width: 78%;
                  }
                }

                .p-dropdown {
                  height: 32px;
                  margin: 0;
                  min-width: 100%;
                }
              }
            }
          }
        }
      }

      .search-and-export-data-panel {
        label {
          &.search-icon {
            left: -245px;
            position: relative;
            top: 0;
          }
        }
      }
    }
  }

  .page-layout {
    .form-container {
      .form-field {
        input {
          &[type='text'] {
            font-size: 13px;
            height: 30px;
            margin: 3px 0;
          }
        }

        .form-label {
          font-size: 13px;
          font-weight: 700;
        }

        .p-dropdown {
          font-size: 13px;
          height: 30px;
          margin: 3px 0;
        }
      }
    }

    label {
      &.search-icon {
        left: 5px;
        position: absolute;
        top: 3px;
      }

      &.dollar-icon {
        left: 14px;
        position: absolute;
        top: 14px;
      }
    }

    .menu-offered {
      .web-header {
        display: none;
      }

      .single-item {
        border-bottom: 1px solid $grey;
        margin-bottom: 5px;
        margin-top: 5px;

        .mob-disp-flx {
          display: flex;
        }
      }

      .menu-title {
        display: inline-block;
        font-size: 13px;
        font-weight: bold;
        width: 34%;
      }
    }
  }

  .p-calendar {
    &.p-calendar-w-btn {
      .p-datepicker-trigger {
        &.p-button {
          height: 2em;
          margin: 4px;
          width: 2em;
        }
      }
    }
  }

  .closed {
    .main-panel {
      margin-left: 0;
      width: 100%;
    }
  }

  .edit-pencil-icon {
    margin-top: 0;
  }

  .width-100per {
    width: 100%;
  }

  .view-details-icon {
    margin-top: 0;
  }

  .save-icon {
    margin-top: 0;
  }

  .deactive-icon {
    margin-top: 0;
  }

  .active-icon {
    margin-top: 0;
  }

  .orders-icon {
    margin-top: 0;
  }

  .p-calendar {
    &.p-calendar-w-btn {
      .p-inputtext {
        margin: 8px 0;
      }

      .p-datepicker-trigger {
        &.p-button {
          margin-left: -2px;
          margin-top: 8px !important;
        }
      }
    }
  }
}

// common media end

.menu-image {
  &.p-dialog {
    border-radius: 8px;
    padding: 30px 40px 15px;

    @media screen and (min-width: 1023px) {
      width: 700px;
    }

    .p-dialog-content {
      border: 0;
    }

    .p-dialog-footer {
      border: 0;
      padding: 0;
    }

    .label-img-div {
      height: 140px;
      position: relative;

      .fix-div-width-height {
        height: 150px;

        .img-class {
          max-height: 95%;
          max-width: 100%;
        }
      }
    }
  }
}

.p-button-outlined {
  &.p-button {
    &.p-state-default {
      background: $white;
      color: $primaryColor;
      margin: 12px;

      .p-button-text {
        font-size: 16px;
        font-weight: 600;
      }
    }

    &:enabled {
      &:focus {
        box-shadow: none;
        outline: none;
      }

      &:hover {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.select-btn {
  &.p-button {
    &.p-state-default {
      margin: 12px;

      .p-button-text {
        font-size: 16px;
        font-weight: 600;
      }
    }

    &:enabled {
      &:focus {
        box-shadow: none;
        outline: none;
      }

      &:hover {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.refund-dialog {
  &.form-container {
    width: 400px;

    .form-field {
      margin-bottom: 11px;

      .form-label {
        color: $labelColor;
        font-family: $fontFamily;
        font-size: 14px;
        font-weight: 700;
        opacity: .8;
      }

      input {
        &[type='text'] {
          border: 1px solid $inputFieldBorderColor;
          border-radius: 5px;
          font-family: $fontFamily;
          font-size: 14px;
          height: $franchiseInputTextHeight;
          margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
          padding: 2% 2% 2% 4%;
          width: 100%;

          &.quarter-ui {
            width: 92%;
          }
        }
      }
    }
  }
}

.event {
  &.fa {
    &.fa-file-excel-o {
      color: $color8;
      margin-top: 10px;
      zoom: 1.4;
    }
  }
}

.action-width {
  width: 110px;
}

.guage-bar {
  height: 2em;
  width: 95%;

  @media (max-width: 400px) {
    width: 100%;
  }
}

.lable-extra-menu,
.mar-t-b-0 {
  bottom: 5px;
  display: inline;
  position: relative;
}

.p-dialogue-button-postition {
  position: relative;
  top: 0;
}

.mar-left {
  margin-left: 20px;
}

.edit-icon-margin {
  cursor: pointer;
  position: relative;
  top: 40px;
}

.menu-radio-button {
  bottom: 5px;
  position: relative;
}

.sidebar-marteking-icon {
  background-image: url('/assets/images/new_icons/reminder_icon.png');
  height: 20px;
  margin-top: 5px;
  width: 22px;
}

.sidebar-waffle-icon {
  background-image: url('/assets/images/sidebar/waffle_emoji_icon.png');
}

.sidebar-migration-icon {
  background-image: url('/assets/images/action_icons/copy1.png');
}

.sidebar-waffle-menu-icon {
  background-image: url('/assets/images/waffle_menu_main.svg');
}
