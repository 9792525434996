@import './staff-theme';
// @import './fullcalendar-vars';

.p-justify-center {
  justify-content: center;
}

.staff-panel {
  font-family: 'Montserrat';
  margin: 0;
  padding: 0;
  position: relative;

  .main-panel {
    background: #FFF;
    left: 4%;
    min-width: 96%;
    padding: 0;
    position: absolute;
    top: 60px;
    width: 96%;
  }

  .form-container {
    .form-field .p-calendar input[type=text] {
      height: 40px;
      margin: 8px 0;
      padding: 0 2%;
      width: 96%;
    }

    .p-dropdown,
    .p-multiselect {
      // padding: 5px;
      background: #FFF;
      border: 1px solid #D4D6D9;
      border-radius: 5px;
      font-family: 'Montserrat';
      height: 40px;
      padding: 0;
      width: 100%;
    }

    .radio-btn-container {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      .small-input {
        width: 30%;
      }
    }

    input {
      background: $white;
      border: 1px solid $inputBorderColor;
      border-radius: 5px;
      font-family: 'Montserrat';
      padding: 5px;

      &[type='text'] {
        height: 40px;
        width: 100%;

        &:disabled {
          border: 1px solid $disabledColor;

          &::placeholder {
            color: $disabledColor;
          }
        }
      }

      &[type='radio'] {
        border: 1px solid $primaryBtnColor;
        cursor: pointer;
        height: 20px;
        width: 20px;

        &:hover {
          background: $disabledColor;
        }
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .availability {
      .form-field {
        border-bottom: .5px solid #D0CCCC;
        min-height: 40px;

        &.btm-br-0 {
          border-bottom: 0;
        }
      }
    }
  }
}

.no-padding {
  padding: 0;
}

.no-y-padding {
  padding-bottom: 0;
  padding-top: 0;
}

.semi-bold-text {
  font-weight: 600;
}

.underline-text {
  text-decoration: underline !important;
}

.main-panel .event-details .submit-btn {
  font-size: 20px;
  width: 100%;
}

.circle {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}


.seperator {
  background: #EDEEF2;

  .line {
    // border-top: 1.5px solid #E1E2E1;
    margin-top: 5px;
    padding: 5px;
  }
}

.gray-dot {
  background-color: #BBB;
}

.white-dot {
  background-color: #FFF;
  border: 1px solid #BBB;
}

.dot {
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  width: 15px;
}


@media screen and (max-width: 600px) {
  .main-panel .main-container .main-content {
    padding: 0;
  }
}

.p-accordion .p-accordion-header .p-icon {
  margin-top: -8px;
  position: absolute;
  right: .5em;
  top: 50%;
}

body .p-accordion .p-accordion-header {
  margin: 0;

  a {
    background: #FFF;
    border: 0;
    border-top: 1px solid #E1E2E1;
    padding: .857rem 1rem;

    .p-accordion-toggle-icon {
      margin-top: 0;
      position: absolute;
      right: .5em;
      top: 50%;
    }
  }
}

body .form-container {
  .p-accordion .p-accordion-header {
    a {
      border-top: 0;
      padding-left: 0;
    }
  }
}

body .p-accordion .last-accordion {
  a {
    border-bottom: 1px solid #E1E2E1;
  }

  .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active a {
    background-color: #E1E2E1;
    border: 1px solid #E1E2E1;
    color: #707070;
    font-weight: normal;
  }
}

body {
  .p-accordion {
    .p-accordion-header {
      .p-accordion-header-link {
        background-color: transparent;
        border: 0;
        border-top: 1px solid #E1E2E1;
        color: #333;
      }
    }
  }

  .ui-accordion {
    .last-accordion {
      a {
        &.p-accordion-header-link {
          border-bottom: 1px solid #E1E2E1;
        }
      }
    }
  }
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {

  background-color: #E1E2E1;
  border: 1px solid #E1E2E1;
  border-top: 1px solid #E1E2E1;
  color: #707070;
  font-weight: 400;
}


body .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active:hover a {
  background-color: #E1E2E1;
  border: 1px solid #E1E2E1;
  color: #707070;
}

body .p-accordion .p-accordion-header:not(.p-state-disabled).p-state-active a .p-accordion-toggle-icon {
  color: #707070;
}

body .p-accordion .p-accordion-content {
  border-left: 0;
  border-right: 0;
}

.p-accordion .p-accordion-header-text {
  color: #707070;
  font-weight: 400;
}

body .ui-inputgroup {
  display: flex;

  .ui-inputgroup-addon {
    background: #E1E2E1;
    border: 0;
    border-radius: 5px 0 0 5px;
    color: #707070;
    font-size: 14px;
    font-weight: 600;
    padding: 15px;
    text-align: left;
    width: 60%;
  }

  .input-text {
    // padding: 15px;
    // width: 100%;
    border: 1px solid #D4D6D9;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-weight: 600;
    height: auto;
    padding-right: 15px;
    text-align: right;
    width: 50%;
  }

  .input-text-half {
    border: 1px solid #D4D6D9;
    border-radius: 5px;
    font-family: 'Montserrat';
    font-weight: 600;
    padding-right: 15px;
    text-align: right;
    width: 20%;
  }

  .input-dropdown {
    font-family: 'Montserrat';
    text-align: right;
    width: 50%;
  }

  .input-textarea {
    //font-weight: 600;
    border: 1px solid #D4D6D9;
    border-radius: 5px;
    font-family: 'Montserrat';
    padding-right: 15px;
    width: 100%;
  }

  .ui-dropdown {
    border: 1px solid #D4D6D9;
    height: auto;
    margin: 0;
    padding: 10px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  //* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

body .event-details .p-widget .p-inputswitch {
  top: 5px;
}

.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item {
      text-align: left;

      &.p-highlight {
        background: #007AD9;
        color: #FFF;
        text-align: left;
      }
    }
  }
}

.p-accordion-tab-active {
  .p-accordion-content {
    border: 0;

    .p-calendar {
      .p-datepicker {
        background-color: #EDEEF2;

        table {
          td {
            span {
              border: 0;
              border-radius: 3px;
              color: #333;
              cursor: pointer;
              display: block;
              height: auto;
              padding: 0;
              text-align: center;
              text-decoration: none;
              transition: box-shadow .2s;
              width: auto;
            }
          }
        }
      }
    }
  }
}

#p-accordiontab-2-content {
  .p-dropdown {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: auto;
    margin: 0;
    padding: 5px;

    .p-dropdown-trigger {
      margin-bottom: -6px;
      margin-right: -6px;
      margin-top: -6px;
    }
  }
}
