.mbsc-ios.mbsc-schedule-wrapper,
.mbsc-timeline.mbsc-ltr {
  z-index: 0 !important;
}

.mbsc-ios.mbsc-calendar {
  border: 1px solid #D4D6D9;
  font-family: "Montserrat";

  .mbsc-ios.mbsc-calendar-button-today {
    background: #568EE8;
    border: 0 !important;
    color: #FFF;
    font-family: 'Montserrat';
    font-size: 1rem;
    font-weight: 600;
    max-height: 40px;
    min-height: 40px;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    transition: all .5s ease;

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  // .mbsc-schedule-time-wrapper {
  //   @media screen and (max-width: 767px) {
  //     width: 3.25em
  //   }
  // }

  .mbsc-ios.mbsc-schedule-header {
    border-bottom: none;
  }

  .mbsc-schedule-event {
    padding: 0;
  }

  .mbsc-schedule-events.mbsc-ltr {
    right: 0;
  }

  // .mbsc-schedule-events.mbsc-ltr:last-child {
  //   border-right: 1px solid #CCC !important;
  // }

  .mbsc-schedule-column-inner:last-child {
    border-right: 1px solid #CCC !important;
    cursor: pointer;
  }

  .mbsc-calendar-day-outer {
    pointer-events: none;
  }

  .mbsc-schedule-events.mbsc-ltr:last-child {
    border-right: 1px solid #CCC !important;
  }

  .mbsc-schedule-event-background {
    border: 1px solid #707070 !important;
    bottom: 0;
    left: 0;
    opacity: 1 !important;
    right: 0;
    top: 0;
  }

  .mbsc-schedule-resource-title {
    // padding-top: 1em;
    // padding-bottom: 1em;

    &:last-child {
      border-right: 1px solid #CCC !important;
    }
  }

  .mbsc-schedule-column {
    padding: 0;
  }

  // .mbsc-schedule-item {
  //   border-color: #D4D6D9;
  //   height: 2em;
  // }

  .header-resource-name {
    color: #707070;
    font-size: 14px;
  }

  // .mbsc-ios.mbsc-schedule-header-item:first-child {
  //   display: none;
  // }

  .mbsc-schedule-event-hover .md-custom-event-cont .md-custom-event-wrapper {
    background: none;
  }

  // .mbsc-ios.mbsc-schedule-header-item-large.mbsc-schedule-header-item {
  //   display: none;
  // }

}

.mbsc-schedule-col-width {
  min-width: 200px;
  width: 200px;
  // max-width: 230px;
}

.mbsc-ios.mbsc-schedule-header-item:first-child {
  display: none;
}

// added for hide whole week calendar when day view selected & single resource is selected
.mbsc-ios.mbsc-schedule-header-item.mbsc-ltr {
  display: none;
}

// .mbsc-schedule-grid-scroll, .mbsc-timeline-grid-scroll {
//   overflow-x: auto !important;
//   overflow-y: auto !important;
// }

#mobiscroll-container {
  height: 600px;
  // overflow: auto;
  // border-top: 1px solid #e9e9e9;
  // border-bottom: 1px solid #e9e9e9;

  // min-height: 600px;
  .mbsc-calendar-width-md .mbsc-schedule-event-range {
    display: none !important;
  }

}


.mbsc-schedule-resource-title {
  // display: flex;
  display: block;
  justify-content: center;
}


.mbsc-material .md-header-filter-prev {
  order: 1;
}

.mbsc-material .md-header-filter-next {
  order: 2;
}

.mbsc-material .md-header-filter-controls {
  order: 3;
}

.mbsc-material .md-header-filter-today {
  order: 4;
}

.calendar-btn-container {
  left: 15px;
  position: absolute;
  top: 15px;
}

.today-btn-container {
  position: absolute;
  right: 15px;
}

.custom-date {
  color: #707070;
  font-size: 16px;
  padding: 10px 0;

  @media screen and (max-width: 767px) {
    font-size: 12px;
    padding: 10px 0;
  }
}

.mbsc-icon {
  color: #707070;
}


.md-custom-header-controls {
  align-items: center;
  background-color: #FFF;
  border: 0;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
}

.mbsc-ios.mbsc-schedule-header {
  background-color: #FFF;
  border-top: 1px solid #D4D6D9;
}


.md-custom-header-nav,
.md-custom-header-view {
  width: 180px;
}

.md-custom-header-button.mbsc-button {
  font-size: 20px;
  height: auto;
  margin: 0;
  padding: 0;
}

.md-custom-header .mbsc-segmented {
  float: right;
  margin-bottom: 0;
  margin-top: 0;
  width: 110px;
}

.md-custom-header .mbsc-material.mbsc-segmented,
.md-custom-header .mbsc-windows.mbsc-segmented {
  padding: 0;
}

.md-custom-header .mbsc-segmented .mbsc-segmented-button {
  font-size: 20px;
  height: 32px;
  padding: 0;
}

.mbsc-ios.mbsc-schedule-date-header {
  display: none;
}

.mbsc-timeline-header-sticky {
  display: none !important;
}


// Custom event styles
.md-custom-event-cont {
  // box-shadow: 0 0 5px rgba(0, 0, 0, .3);
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  overflow: hidden;
  padding-top: 5px;
  position: relative;
}

.md-custom-event-wrapper {
  // background: rgba(255, 255, 255, .5);
  box-sizing: border-box;
  height: 100%;
  position: relative;
  transition: background .15s ease-in-out;
  width: 100%;
}

.mbsc-schedule-event-hover {
  opacity: 1;
}

.mbsc-schedule-event-hover .md-custom-event-cont .md-custom-event-wrapper {
  background: rgba(255, 255, 255, .3);
}

// .mbsc-schedule-event-active .md-custom-event-cont .md-custom-event-wrapper {
//   // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12), 0 1px 3px -1px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12);
// }


.md-custom-event-category {
  color: #EEE;
  display: inline-block;
  margin: 10px;
  max-width: 100%;
  overflow: hidden;
  padding: 2px 10px;
  white-space: nowrap;
}

.md-custom-event-details {
  color: #FFF;
  padding: 0 10px;
}



.md-custom-event-time {
  font-size: 12px;
  padding-bottom: 1px;
}

.md-custom-event-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.md-custom-event-allday-title {
  // opacity: .7;
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
}

.md-custom-event-btn.mbsc-button {
  line-height: 20px;
  margin: 5px 0;
  padding-bottom: 0;
  padding-top: 0;
}

.md-cutom-event-img-cont {
  bottom: 5px;
  left: 10px;
  position: absolute;
  white-space: nowrap;
}

.md-custom-event-img {
  height: 25px;
  margin: 0 2px;
  width: 25px;
}


// Custom event style for week view
.week-view {

  .mbsc-timeline-row {
    height: 4em;
  }

  .mbsc-timeline-resource {
    height: 4em;
  }

  .md-timeline-height .mbsc-timeline-resource,
  .md-timeline-height .mbsc-timeline-row {
    min-height: 120px;
  }

  .mbsc-schedule-event-hover .md-custom-event-cont .md-custom-event-wrapper {
    width: 100%;
  }

  .mbsc-ios.mbsc-calendar .mbsc-schedule-event {
    min-height: 44px;
  }

  //
  // .mbsc-timeline-event-listing.mbsc-schedule-event-all-day-placeholder {
  //   display: none;
  // }

  // .mbsc-timeline-grid-scroll {
  //   margin-top: 10px;
  // }

  .mbsc-ios.mbsc-timeline-resource-bg,
  .mbsc-ios.mbsc-timeline-resource-empty {
    // min-height: 62px;
    // height: -webkit-fill-available;
    min-height: 100%;
  }

  .mbsc-timeline-header-date {
    display: flex;
    justify-content: center;
    min-height: 36px;
    padding: 5px 0;
  }

  .mbsc-timeline-slot-title {
    color: #007AD9;
    text-align: center;
  }

  .slot-name {
    align-items: center;
    display: flex;
    justify-content: center;

    .event-icon {
      height: 15px;
      margin-top: 5px;
      width: 15px;
    }
  }

  .md-custom-event-cont {
    // box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    align-items: center;
    background: #FFF;
    border: 1px solid #707070;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 100%;
    min-height: 44px;
    overflow: hidden;
    padding: 5px;
    position: relative;
  }

  .md-cutom-event-img-cont {
    // bottom: 0;
    // left: auto;
    // right: 0;
    // top: -8px
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: initial;
    white-space: nowrap;
  }

  .md-custom-event-details {
    // align-content: center;
    // align-items: center;
    // display: flex;
    // justify-content: space-between;
    display: block;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  .fa-fw {
    margin: 2px 0;
  }
}

.event-week-view {

  .mbsc-ios.mbsc-timeline-resource-bg,
  .mbsc-ios.mbsc-timeline-resource-empty {
    min-height: 36px;
  }
}

.mbsc-ios.mbsc-schedule-event-bar {
  display: none;
}

// .mbsc-timeline-day {
//   .mbsc-timeline-slot {

//     // .mbsc-timeline-events {
//     //   // top: 13px;
//     // }
//   }
// }

.upper-space {
  position: relative;
  top: 10px;
}

.mbsc-calendar-label-background {
  border: 1px solid #000;
  border-radius: 10px;
  opacity: unset;
}

.mbsc-ios .mbsc-calendar-label-background {
  opacity: unset !important;
}

.mbsc-calendar-label-inner .mbsc-ios .ng-star-inserted {
  margin-top: 15px;
}

.cusome-2l-label {
  border: 1px solid;
  border-radius: 7px;
  bottom: 14px;
  position: relative;
  text-align: center;
}

.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
  min-height: 6em !important;
}

.month-calender {
  .ui-calendar .ui-datepicker {
    width: 200px;
  }
}

.mbsc-ios.mbsc-event-color {
  border: .5px solid;
}

.mbsc-ios.mbsc-popover-list {
  font-family: 'Montserrat';
  font-size: 14px;
}

.mbsc-ios.mbsc-list-item {
  border-bottom: 1px solid #FFF;
  margin-top: -1px;
  padding: 0 !important;
}

.mbsc-ios.mbsc-list-item:after,
.mbsc-ios.mbsc-ios.mbsc-list-item:last-child::after,
.mbsc-ios.mbsc-list-item:before {
  content: '';
  left: 0 !important;
  position: absolute;
  right: 0;
}

.mbsc-ios.mbsc-list-item:after {
  border-bottom: .2px solid #CCC !important;
}


.week-view {
  .mbsc-ios.mbsc-calendar {
    .mbsc-timeline-row {
      cursor: pointer;
      min-height: 66px;

      .mbsc-timeline-slot {
        .mbsc-timeline-events {
          // height: 100%;

          .mbsc-schedule-event {
            // margin-bottom: -25px;
            // padding-bottom: 12px;
            margin-bottom: -12px;
            padding-bottom: 0;
          }

          .mbsc-timeline-event-slot {
            .md-custom-event-wrapper {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }

    .mbsc-timeline-resource-col {
      .mbsc-timeline-resource {
        min-height: 66px;
      }
    }
  }
}
