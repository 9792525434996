.mrg-30 {
  margin-top: 30px;
}

.edit-profile {
  .form-field {
    input {
      &[type='text'] {
        width: 100%;
      }
    }
  }

  .ui-fileupload-buttonbar {
    background: $white;
  }
}

.form-field {
  .lable-mobile-number {
    padding: 4px .5em;
  }

  .p-inputgroup {
    .p-inputgroup-addon {
      font-size: 10px;
      margin: 8px 0;
      padding: 9px 1px;
    }

    .input,
    .input-mobile-code {
      border: 1px solid $inputFieldBorderColor;
      height: 30px;
      padding: 0 4px;
      width: 65%;
    }
  }

  &.mt-15 {
    margin-top: 15px 0 0 0;
  }

  &.multiselect-text {
    input {
      &[type='text'] {
        padding: 0 18px;
      }
    }
  }

  p-rating {
    .ui-rating {
      margin-top: 5px;

      &:active,
      a {
        font-size: 28px;
      }
    }
  }

  label {
    margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;

    &.ui-radiobutton-label {
      padding-left: 4px;
    }
  }

  .anomymous {
    .p-chkbox-label {
      color: #FFF;
      font-size: 16px;
      font-weight: 600;
      padding-left: 4px;
    }

    &.cc-charges {
      .p-chkbox-label {
        font-size: 15px;
        font-weight: 400;
      }
    }
  }

  .checkbox {
    margin-top: 12px;

    .p-checkbox-label {
      padding-left: 4px;
    }

    &.terms-checkbox {
      .p-checkbox-label {
        color: #FFF;
      }

      .term-link {
        margin-left: 10px;
        margin-top: 3px;
        position: absolute;
        text-decoration: underline;
      }
    }
  }

  textarea {
    border: 1px solid $inputFieldBorderColor;
    font-family: 'Montserrat';
    font-size: 14px;
    height: $inputTextHeight + 100;
    margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
    padding: 1% 2%;
    width: 96%;
  }

  input {
    &[type='text'] {
      border: 1px solid $inputFieldBorderColor;
      font-family: 'Montserrat';
      font-size: 14px;
      height: $inputTextHeight;
      margin: 8px $formFieldLeftRightMargin;
      padding: 0 2%;
      width: 96%;

      &.quarter-ui {
        width: 92%;
      }
    }

    &[type='password'] {
      height: $inputTextHeight;
      margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
      padding: 0 2%;
      width: 96%;
    }

    &:read-only {
      background: $readOnlyFieldColor;
    }

    &.full-width {
      width: 100%;
    }
  }

  p-radiobutton {
    padding: 5% 5% 5% 0;
  }

  p-checkbox {
    &.vip-chk {
      vertical-align: sub;
    }
  }

  .ui-calendar {
    width: 100%;

    input {
      &[type='text'] {
        // height: 30px;
        margin: 8px 0;
        padding: 0 2%;
        width: 96%;
      }
    }
  }

  p-selectbutton {
    .ui-state-default {
      background: $mainContentBgColor;
      border: 1px solid $selectButtonBorderColor;
      color: $black;
    }

    .ui-state-active {
      &.ui-state-default {
        background: $defaultThemeColor;
        border-color: $contentBorderColor;
        color: $white;
      }
    }
  }
}

.display-image {
  &.profile-btn {
    .ui-fileupload-buttonbar {
      background: $mainContentBgColor;
    }
  }

  .ui-fileupload-buttonbar {
    background: $mainContentBgColor;
    border: 0 none;
    text-align: center;
  }

  .ui-fileupload {
    position: relative;
    right: 15px;

    .ui-fileupload-content {
      display: none;
    }

    .ui-fileupload-buttonbar {
      background: transparent;
      border: 0 none;
      text-align: center;
      width: 185px;

      .ui-button-icon-left {
        display: none;
      }

      .ui-button-text {
        padding-left: 1em;
      }
    }
  }
}

.ui-fileupload-buttonbar {
  padding: .5em 0;
}

.ui-inputtext {
  &.ng-dirty {
    &.ng-invalid {
      &:hover {
        border-color: $stateFocusBorderColor;
      }
    }

    &.ui-dropdown {
      height: $inputTextHeight;
      margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
      width: 96%;
    }
  }
}

.form-with-image {
  padding-right: 6%;
}

.form-buttons {
  margin: 0 $formFieldLeftRightMargin;

  &.form-buttons-left-padded {
    margin: $formFieldTopBottomMargin -70px;
  }

  &.button-pad {
    padding-right: 5.4%;
  }

  button {
    display: block;
    float: right;
    margin: 8px 4px;
  }

  &::after {
    clear: both;
    content: '';
    display: block;
  }

  .ui-button-text-icon-right {
    padding: 4px 1px;
  }
}

.ui-dropdown {
  background: $contentBgColor;
  height: 32px;
  margin: .3em 0;
  min-width: 100%;

  &:not(.ui-state-disabled) {
    &:hover {
      background-color: $contentBgColor;
      border-color: $stateFocusBorderColor;
    }
  }

  .ui-state-default {
    border: 0;
  }

  .ui-dropdown-label {
    margin: 0;
    padding: 5px 8px;
  }

  .p-dropdown-trigger {
    .fa {
      margin-left: -3px;
      margin-top: 8px;
    }
  }
}

.ui-fileupload-content {
  display: none;
  padding: 1em;
  position: relative;
  transition: border-color .3s;
}

.right-aligned-text {
  text-align: right;
}

.add-routes-popup {
  height: auto;
  width: 71%;
}

.add-popup {
  height: auto;
  width: 35%;
}

fieldset {
  &.ui-widget-content {
    legend {
      &.ui-state-default {
        background: $mainContentBgColor;
        color: $contentTextColor;
      }
    }
  }
}

.ui-fieldset {
  &.ui-fieldset-toggleable {
    .ui-fieldset-legend {
      &:hover {
        background: $mainContentBgColor;
        border-color: $inputFieldBorderColor;
        color: $contentTextColor;
      }
    }
  }
}

.p-multiselect {
  margin: 8px 0;
  padding-right: 2%;
  width: 100%;

  &:not(.ui-state-disabled) {
    &:hover {
      background-color: $contentBgColor;
      border-color: $stateFocusBorderColor;
      color: $contentTextColor;
    }
  }

  &.p-state-default {
    background: $contentBgColor;
    color: $contentTextColor;
    margin: 6px 0;
  }

  .p-multiselect-label {
    margin: 0;
    padding: 0 8px;
  }

  .p-multiselect-trigger {
    .fa {
      margin-left: -3px;
      margin-top: 8px;
    }
  }
}

.ng-dirty {
  &.ng-invalid {
    .p-multiselect {
      &:not(.ui-state-disabled) {
        &:hover {
          border-color: $stateFocusBorderColor;
        }
      }
    }
  }
}

.p-multiselect-header {
  .p-multiselect-filter-container {
    .fa {
      left: 1px;
      position: absolute;
      top: 15px;
    }
  }
}

.form-errors {
  color: $stateErrorTextColor;
  font-family: Montserrat;
  font-size: 16px;
  text-align: left;


  &.align-right {
    text-align: right;
  }
}

.error-message {
  color: $stateErrorTextColor;
  font-family: Montserrat;
  font-size: 15px;
  padding: 6px 0 0 10px;
  text-align: right;
}

.autocomplete {
  &.quarter-ui {
    input {
      &[type='text'] {
        width: 88%;
      }
    }
  }

  input {
    &[type='text'] {
      height: $inputTextHeight;
      margin: $formFieldTopBottomMargin $formFieldLeftRightMargin;
      padding: 0 2%;
      width: 94%;
    }
  }
}

.ui-autocomplete {
  width: 100%;
}

.edit-profile-popup {
  height: auto;
  width: 50%;
}

.popup {
  height: auto;
  width: 50%;
}

.add-hotel-popup {
  height: 500px;
  width: 50%;
}

.add-edit-route-popup {
  max-height: 500px;
  width: 65%;

  .ui-dialog-content {
    max-height: 450px;
    overflow-y: auto;
    padding: 0;
  }
}

.form-mrg {
  padding-right: 5%;

  .ui-calendar {
    .ui-button {
      &.ui-state-default {
        height: 31px;
        margin-top: 8px;
      }
    }
  }

  .fieldset-style-class {
    width: 100%;
  }
}

.dispatcher-role-table {
  border: 1px solid $mainContentBgColor;
  border-collapse: collapse;
  margin: 7px 0;
  width: 96%;

  .table-header {
    background-color: $gridHeaderBgColor;
    border: 1px solid $mainContentBgColor;
    border-bottom: 10px solid $mainContentBgColor;
    color: $tableHeaderText;
    height: 30px;
    margin-bottom: 10px;
    padding: 10px;
    text-align: left;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .table-rows {
    background-color: $inputBgColor;
    height: 60px;
    padding: 10px;
  }

  .col-header {
    padding: 10px;
  }

  th {
    &.col-header-center {
      border-radius: 5px;
      padding: 10px;
      text-align: center;
    }
  }

  td {
    &.col-header-center {
      padding: 10px;
      text-align: center;
    }
  }
}

.quarter-ui {
  .ui-dropdown {
    min-width: 92%;
  }
}

.bookings-autocomplete {
  input {
    &[type='text'] {
      width: 100%;
    }
  }

  .ui-button {
    &.ui-state-default {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      position: absolute;
      right: 0;
      top: 8px;
    }
  }
}

.mobile-number {
  padding: 0 .5em;
}
