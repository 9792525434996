// common media start
@media screen and (max-width: 600px) {
  .main-panel {
    margin-left: 210px;

    .main-container {
      .main-content {
        padding: 7px;
      }
    }

    .p-button {
      &.p-state-default {
        font-size: 13px;
        margin-bottom: 0;
        margin-left: 3px;
      }
    }

    .mi-grid {
      padding: 15px 5px;

      p-table {
        .p-table {
          .p-table-tbody {
            tr {
              td {
                display: flex;
                font-size: 13px;
                padding: .571em .857em;

                .p-column-title {
                  min-width: 34%;
                  overflow: hidden;
                  text-overflow: unset;
                  white-space: normal;
                  width: 34%;
                }

                .eclipse {
                  div {
                    overflow: hidden;
                    text-overflow: unset;
                    white-space: initial;
                    width: 78%;
                  }
                }

                .p-dropdown {
                  height: 32px;
                  margin: 0;
                  min-width: 100%;
                }
              }
            }
          }
        }
      }

      .search-and-export-data-panel {
        label {
          &.search-icon {
            left: -245px;
            position: relative;
            top: 0;
          }
        }
      }
    }
  }

  .page-layout {
    .form-container {
      .form-field {
        input {
          &[type='text'] {
            border: 1px solid #DDD;
            font-family: $fontFamily;
            font-size: 13px;
            height: 40px;
            margin: 6px 0;
            padding: 0 2%;
            width: 100%;

            &.prefix-pad {
              padding-left: 25px;
            }
          }
        }

        .form-label {
          font-size: 13px;
          font-weight: 700;
        }

        .p-dropdown {
          font-size: 13px;
          height: 30px;
          margin: 3px 0;
        }
      }

      .image-upload {
        height: auto;
        width: 44%;
      }
    }

    label {
      &.search-icon {
        left: 5px;
        position: absolute;
        top: 10px;
      }

      &.dollar-icon {
        left: 14px;
        position: absolute;
        top: 14px;
      }
    }

    .menu-offered {
      .web-header {
        display: none;
      }

      .single-item {
        border-bottom: 1px solid $grey;
        margin-bottom: 5px;
        margin-top: 5px;

        .mob-disp-flx {
          display: flex;
        }

        .top-pad {
          padding-top: .5rem;
        }

        input {
          &[type='text'] {
            &.offered-price {
              margin: 0;
              padding-left: 25px;
              width: 50%;
            }
          }
        }

        .offered-price-sign {
          opacity: .6;
          position: relative;
          right: 47%;
        }
      }

      .menu-title {
        display: inline-block;
        font-size: 13px;
        font-weight: bold;
        width: 34%;
      }
    }

    .search-and-export-data-panel {
      .export-button {
        bottom: -61px;
        left: 19px;
        position: relative;
      }
    }

    .display-image {
      .p-fileupload {
        position: relative;
        right: 20px;

        .p-button {
          &.p-button-text-icon-left {
            .p-button-text {
              padding: .429em 1em .429em 2em;
            }
          }
        }

        .p-fileupload-buttonbar {
          width: 54%;
        }
      }
    }

    //  -----after merge start----//

    .disp-web-flex {
      display: block;
    }

    .menu-list {
      display: block;

      .web-hide {
        display: block;
        width: 40%;

        label {
          font-size: 13px;
          font-weight: bold;

          &.image-label {
            padding-left: .5rem;
          }
        }
      }

      label {
        &.dollar-icon {
          left: 4%;
          opacity: .8;
          position: absolute;
          top: 15%;
        }
      }

      .price {
        padding-left: 15px;
      }

      .cross-icon {
        cursor: pointer;
        margin-top: 7px;
      }

      .each-item {
        margin-top: 0;

        &.image {
          .image-div {
            margin-left: 0;
          }
        }
      }

      .menu-image-list {
        width: 70%;
      }
    }

    .mobile-hide {
      display: none;
    }

    ///--------------after merge end---------------- //
  }

  .p-calendar {
    &.p-calendar-w-btn {
      .p-datepicker-trigger {
        &.p-button {
          height: 1.2em;
          margin-right: 0;
          margin-top: 0;
          width: 2.5em;
        }
      }
    }
  }

  .closed {
    .main-panel {
      margin-left: 0;
      width: 100%;
    }
  }

  .edit-pencil-icon {
    margin-top: -5px;

    &.menu-pencil {
      margin-top: -5px;
    }
  }

  .cancel-icon-menu {
    margin-top: 0;
  }

  .manage-icon-menu {
    padding-top: 0;
  }

  .width-100per {
    width: 100%;
  }

  .view-details-icon {
    margin-top: 0;
  }

  .save-icon {
    margin-top: 0;
  }

  .deactive-icon {
    margin-top: 0;
  }

  .active-icon {
    margin-top: 0;
  }

  .orders-icon {
    margin-top: 0;
  }

  .clone-icon {
    margin-top: 0;
    zoom: 1.4;
  }

  .event-hold-icon {
    margin-top: 0;
  }

  .event-open-icon {
    margin-top: 0;
  }

  .event-order-report-excel {
    margin-top: 0;
  }

  .event-delete {
    margin-top: -3px;
  }

  .event-clone {
    margin-top: 0;
  }

  .cancel-icon {
    margin-top: 0;
  }

  .mob-pad-0 {
    padding: 0;
  }

  body {
    .menu-image {
      &.p-dialog {
        .p-dialog-content {
          overflow: scroll;
          width: 280px;
        }
      }
    }
  }

  .mobile-justify-end {
    text-align: end;
  }

  .forgot-popup {
    width: 295px;
  }

  .franchise-selection-popup {
    width: 295px;
  }

  .event {
    &.fa {
      &.fa-file-excel-o {
        margin-top: 0;
      }
    }
  }

  .entries-summery {
    bottom: 0;
    left: 5px;
    position: relative;
  }
}

// common media end
@media screen and (max-width: 600px) {
  .anchor-btn {
    a {
      &.p-button {
        height: 33px;
        margin-left: 10px;
        width: 45%;

        &.upper {
          width: 43%;
        }
      }
    }
  }

  .refund-dialog {
    &.form-container {
      width: 300px;
    }
  }

  .forgot-popup1 {
    width: 80vw;
  }

  .footer-dialog {
    &.ccpa-dialog {
      &.p-dialog {
        .p-dialog-content {
          max-width: 250px;
        }
      }
    }
  }
}

// mobile landscape start
@media screen and (min-width: 610px) and (max-width: 740px) {
  .page-layout {
    .disp-web-flex {
      display: block;
    }

    .menu-list {
      display: block;

      .web-hide {
        display: block;
        width: 40%;

        label {
          font-size: 13px;
          font-weight: bold;

          &.image-label {
            padding-left: .5rem;
          }
        }
      }

      &.mobile-hide {
        display: none;
      }

      .each-item {
        margin-top: 0;

        &.image {
          .image-div {
            margin-left: 0;
          }
        }
      }

      &.form-container {
        .form-field {
          input {
            &[type='text'] {
              height: auto;
              width: 96%;
            }
          }
        }
      }
    }

    .menu-offered {
      .web-header {
        display: none;
      }

      .menu-title {
        display: inline-block;
        font-size: 13px;
        font-weight: bold;
        width: 34%;
      }

      .single-item {
        .mob-disp-flx {
          display: flex;
        }

        .menu-offered-img-width {
          width: 14%;
        }
      }
    }

    .anchor-btn {
      a {
        &.p-button {
          height: 37px;
          margin-left: 10px;
          width: 35%;

          &.upper {
            width: 35%;
          }
        }
      }
    }
  }

  .action-width {
    width: 130px;
  }
}

// mobile landscape end
