@import './admin-theme';
@import './fullcalendar-vars';

.bold-text {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.timezone-span {
  color: #568EE8;
  font-size: 12px;
}

.admin-panel,
.staff-panel {
  font-family: 'Montserrat';
  margin: 0;
  padding: 0;
  position: relative;

  .main-panel {
    background: #EDEEF2;
    left: 4%;
    min-width: 96%;
    padding: 17px;
    position: absolute;
    top: 60px;
    width: 96%;
  }

  app-admin-setting-list {
    p-table {
      .p-datatable-table {
        .p-datatable-thead {
          tr {
            th {
              &:nth-child(2) {
                width: 32%;
              }

              color: #333;
              font-weight: 700;
              text-align: center;
            }
          }
        }

        .p-datatable-tbody {
          tr {
            background-color: #FFF;

            &:nth-child(2n) {
              background-color: #F9F9F9;
            }

            td {
              background: none;
              border-left: 0;
              border-right: 0;
              padding: .571em .857em;
              text-align: center;

              span {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }

  .page-container {
    background: $white;
    border-radius: 5px;
    margin: 0;
    padding: 1rem;

    .border-div {
      border: 1px solid $gridBorderColor;
      margin: 1rem 0;
      width: 100%;
    }

    .page-header {
      align-items: center;
      color: $defaultThemeColor;
      display: flex;
      justify-content: space-between;

      .page-heading {
        display: flex;
        justify-content: space-between;
        width: 30%;

        .heading {
          margin-top: 5px;
        }
      }

      .page-actions {
        display: flex;
        justify-content: flex-end;
        position: relative;
        width: 70%;

        button {
          margin: 0 5px;

          &:last-child {
            margin-right: 0;
          }
        }

        .action-dropdown-container {
          margin-right: 10px;

          .p-dropdown {
            margin: 0;
            width: 250px;
          }
        }

        &.history-report-header {
          .action-dropdown-container {
            margin-right: 0;
          }
        }

        &.extra-header-labels {
          width: 70% !important;
        }
      }
    }

    @media (max-width: 460px) {
      .page-header {
        flex-direction: column;

        .page-heading {
          width: 100% !important;
        }

        .page-actions {
          width: 100%;
        }
      }
    }

    .page-filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .filter-left-container {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }

      .filter-right-container {
        align-items: center;
        display: flex;
        justify-content: flex-end;
      }

      .filter-item {
        align-items: center;
        display: flex;

        @media (max-width: 767px) {
          display: inline-block;
        }
      }

      .form-label {
        color: $inputLabelTextColor;
        font-size: 1rem;
        font-weight: normal;
        text-align: left;
      }
    }

    .page-body {
      margin: 1rem 0;

      .sub-header-container {
        width: 100%;

        h3 {
          color: $inputLabelTextColor;
          font-size: 14px;
          margin: .5rem 0;
        }
      }

      .label {
        color: $inputLabelTextColor;
        font-size: 14px;
        font-weight: 400;
        word-break: break-word;
      }

      .value {
        color: $inputLabelTextColor;
        font-size: 14px;
        font-weight: 600;
        word-break: break-word;
      }

      .phone-with-country-code {
        display: flex;
        justify-content: space-between;

        .country-code-dropdown {
          width: 23%;
        }

        .phone-number-input {
          width: 75%;
        }
      }

      // ------------  Grid Style start --------------------------------------------//

      .data-grid-lines {
        background: $white;
        border-radius: 5px;

        .p-table-thead {
          tr {
            background: $white;

            th {
              background: #F5F5F6;
              border: 1px solid #D8D9FA;
              border-bottom: 0;
              color: $inputLabelTextColor;
              font-weight: 600;
              padding: 10px;
              text-align: left;

              &:last-child {
                border-top-right-radius: 5px;
              }

              &:first-child {
                border-top-left-radius: 5px;
              }
            }
          }
        }

        .p-table-scrollable-body {
          .p-table-tbody {
            tr {
              background: $white;
            }

            td {
              background: $white;
              border: 1px solid #D8D9FA;
              color: $inputLabelTextColor;
              font-weight: 400;
              padding: 0 10px;
              text-align: left;

              @media screen and (max-width: 767px) {
                padding: 5px 10px;
              }
            }
          }
        }

        .p-table {
          .p-paginator {
            border: 0;
            color: $inputLabelTextColor;

            .p-paginator-pages .p-paginator-page .p-state-active {
              background: $defaultThemeColor;
              border-radius: 5px;
            }

            .p-paginator-page {
              border-radius: 5px;

              &:hover {
                border-radius: 5px;
              }
            }

            .p-paginator-first,
            .p-paginator-last,
            .p-paginator-next,
            .p-paginator-prev {
              border-radius: 5px;

              &:hover {
                background: $paginationHoverColor;
              }
            }
          }

          .p-paginator-bottom {
            margin: 10px;
          }
        }
      }

      // ------------  Grid Style end --------------------------------------------//

      // ------------  Form start --------------------------------------------//

      .form-container,
      .form-container-column {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .form-item {
          display: flex;
          flex-direction: column;
          margin-right: 4%;
          padding: 5px 0;

          .form-field {
            .form-label {
              color: $inputLabelTextColor;
              font-size: 14px;
              font-weight: normal;
              text-align: left;
            }

            input,
            textarea {
              background: $white;
              border: 1px solid $inputBorderColor;
              border-radius: 5px;
              font-family: 'Montserrat';
              height: 40px;
              margin: 8px 0;
              max-width: 100%;
              padding: 5px;
              width: 100%;

              &:focus {
                box-shadow: none;
                outline: none;
              }

              &:disabled {
                // border: 1px solid $disabledColor;
                border: 1px solid $inputBorderColor;
                color: $disabledColor;

                &::placeholder {
                  color: $disabledColor;
                }
              }
            }

            textarea {
              height: 100px;
            }

            .input-container {
              font-family: 'Montserrat';
              position: relative;
              width: 100%;

              .p-input-icon-left {
                display: inline-block;
                position: relative;
                width: 100%;

                i:first-of-type {
                  color: #6C757D;
                  left: .5rem;
                  margin-top: -.5rem;
                  position: absolute;
                  top: 50%;
                }
              }

              .icon-input {
                background: $white;
                border: 1px solid $inputBorderColor;
                border-radius: 5px;
                font-family: $fontFamily;
                font-size: 14px;
                height: 40px;
                margin: 8px 0;
                padding: 5px;
                width: 100%;
              }

              .prefix-pad {
                padding-left: 2rem;
              }
            }

            .p-dropdown,
            .p-multiselect {
              // padding: 5px;
              background: $white;
              border: 1px solid $inputBorderColor;
              border-radius: 5px;
              height: 40px;
              margin: 8px 0;
              padding: 0 !important;
              width: 100%;

              &:focus {
                box-shadow: none;
                outline: none;
              }

              &:disabled {
                border: 1px solid $disabledColor;

                &::placeholder {
                  color: $disabledColor;
                }
              }

              .p-dropdown-trigger,
              .p-multiselect-trigger {
                background: $defaultThemeColor;

                .p-dropdown-trigger-icon,
                .p-multiselect-trigger-icon {
                  color: $white;
                }

                &:hover {
                  background: $dropdownHoverColor;
                }
              }
            }

            .editable-dropdown {
              .p-dropdown {
                padding: 0;
              }

              input {
                border: 0;
                border-bottom: 1px solid #E1E3E7 !important;
                margin: 0;
              }
            }

            .p-widget:disabled {
              border: 1px solid $disabledColor;

              &::placeholder {
                color: $disabledColor;
              }
            }
          }
        }

        h3 {
          color: $inputLabelTextColor;
          margin: 0;
        }
      }

      .form-container-column {
        flex-direction: column;
      }

      // ------------  Form end --------------------------------------------//

      // ------------  Tabs start --------------------------------------------//

      .p-tabview.p-tabview-bottom .p-tabview-nav li,
      .p-tabview.p-tabview-left .p-tabview-nav li,
      .p-tabview.p-tabview-right .p-tabview-nav li,
      .p-tabview.p-tabview-top .p-tabview-nav li {
        background: $white;
        min-width: 120px;
        text-align: center;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background: #F4F5FA;
        }

        a {
          font-weight: normal;
        }

        &:not(.p-state-active):not(.p-state-disabled):hover {
          background-color: #F4F5FA;
          border: 1px solid #DBDBDB;
        }

        @media screen and (min-width: 36px) and (max-width: 460px) {
          border-bottom: 2px solid #D8D9FA;
          min-width: 90px;
        }
      }

      .p-tabview.p-tabview-bottom .p-tabview-nav li.p-state-active,
      .p-tabview.p-tabview-left .p-tabview-nav li.p-state-active,
      .p-tabview.p-tabview-right .p-tabview-nav li.p-state-active,
      .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active {
        background: $white;
        border: 1px solid $gridBorderColor;
        border-bottom: 2px solid $defaultThemeColor;

        &:hover {
          background: $white;
          border-bottom: 2px solid $defaultThemeColor;

          a {
            background: $white;
            border-bottom: 0;
            color: $defaultThemeColor;
          }
        }

        a {
          background: $white;
          border-bottom: 0;
          color: $defaultThemeColor;
          font-weight: 700;
        }
      }

      .p-tabview {
        .p-tabview-panels {
          border: 0;
          border-top: 2px solid $gridBorderColor;
          // padding: 0 1rem;
          padding: 0;
        }

        .p-tabview-nav>li {
          background: $white;
          border-color: #D8D9FA;
          font-weight: 400;
          margin-right: 0;

          .p-state-active {
            border-bottom: 2px solid $gridBorderColor;
            color: $defaultThemeColor;
            font-weight: 600;
          }
        }
      }

      // ------------  Tabs end --------------------------------------------//
    }
  }
}

.staff-panel {
  .page-container {
    padding: 0 !important;
  }
}

// Button styles

.admin-panel,
.staff-panel {

  .primary-btn,
  .p-button {
    background: $primaryBtnColor;
    // border: 0 !important;
    color: $white;
    font-size: 1rem;
    font-weight: 600;
    max-height: 40px;
    min-height: 40px;
    padding: 0;
    position: relative;
    text-align: center;
    transition: all .5s ease;
    padding: 1px 10px;
  }

  .icon-button {
    .p-button {
      max-height: 30px;
      min-height: 30px;
    }
  }

  .qr-code-button {
    .fa-qrcode {
      font-size: 20px;
    }
  }

  .p-selectbutton {
    display: flex;
    justify-content: space-between;

    .p-button {
      align-items: center;
      background: $secondaryBtnColor;
      border: 0 !important;
      border-radius: 50% !important;
      color: $white;
      display: flex;
      height: 30px;
      justify-content: center;
      width: 40px;

      &:first-child {
        border-radius: 50%;
      }

      &:last-child {
        border-radius: 50%;
      }
    }

    .p-highlight {
      background: $primaryBtnColor;
    }

  }

  // Hover style

  .primary-btn,
  .p-button {
    overflow: hidden;
    position: relative;
    transition: all .5s;
    z-index: 1;

    &:after {
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -2;
    }

    &:before {
      background-color: $hoverColor;
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      transition: all .3s;
      width: 0;
      z-index: -1;
    }

    &:hover {
      color: #FFF;

      &:before {
        width: 100%;
      }
    }
  }
}

.p-component {
  .p-autocomplete {
    display: block;
  }
}

.sidebar-panel-menu {
  .p-panelmenu {
    .p-panelmenu-header-link {
      align-items: center;
    }
  }
}

.cancel-btn {
  background: $secondaryBtnColor !important;
  color: $white !important;
  font-size: 1rem;
  font-weight: 600;
  max-height: 40px;
  min-height: 40px;
  padding: 0;
  position: relative;
  text-align: center;
}

.save-btn {
  background: #568EE8;
  border: 0 !important;
  color: #FFF;
  font-size: 1rem;
  font-weight: 600;
  max-height: 40px;
  min-height: 40px;
  padding: 0;
  position: relative;
  text-align: center;
  transition: all .5s ease;
}

.export-btn {
  font-weight: 600;
  max-height: 40px;
  min-height: 40px;
  padding: 0;
  position: relative;
  text-align: center;
}

.import-btn {
  font-weight: 600;
  max-height: 40px;
  min-height: 40px;
  padding: 0;
  position: relative;
  text-align: center;
}

.extra-small-btn-today {
  width: 81px;
}

.small-btn {
  width: 100px;
}

.medium-btn {
  width: 120px;
}

.large-btn {
  width: 150px;
}

.extra-large-btn {
  width: 170px;
}

body .p-widget {
  font-family: 'Montserrat';

  .p-inputswitch {
    top: 9px;
  }
}

.event-page {
  .p-inputswitch {
    top: 9px;
  }
}

body .staff-panel .form-container {
  p-accordion {
    .p-widget {
      // .p-accordion-content-wrapper{
      background-color: #EDEEF2;
      // }
    }
  }
}

// Input styles

.admin-panel {
  .profile-page {
    .password-type {
      height: 40px;
      width: 100%;
    }
  }
}

.admin-panel,
.staff-panel {
  input {
    background: $white;
    border: 1px solid $inputBorderColor;
    border-radius: 5px;
    font-family: 'Montserrat';
    padding: 5px;

    &[type='text'] {
      height: 40px;
      width: 100%;

      &:disabled {
        background-color: #EEEEEE96 !important;
        border: 1px solid $inputBorderColor;
        cursor: not-allowed;
        opacity: 1 !important;

        &::placeholder {
          color: $disabledColor;
        }
      }
    }

    &[type='password'] {
      height: 40px;
      width: 100%;
    }

    &[type='radio'] {
      border: 1px solid $primaryBtnColor;
      cursor: pointer;
      height: 20px;
      width: 20px;

      &:hover {
        background: $disabledColor;
      }
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .editable-dropdown {
    .p-dropdown {
      padding: 0;
    }

    input {
      border: 0;
      border-bottom: 1px solid #E1E3E7 !important;
      margin: 0;
    }
  }

  // Custome Radio button style

  input[type='radio'] {
    -webkit-appearance: none;
    border: 1px solid $primaryBtnColor;
    border-radius: 50%;
    height: 20px;
    outline: none;
    width: 20px;
  }

  input[type='radio']:hover {
    background: $disabledColor;
  }

  input[type='radio']:before {
    border-radius: 50%;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
  }

  input[type='radio']:checked:before {
    background: $primaryBtnColor;
  }

  .p-dialog-visible {
    background: rgba(0, 0, 0, .4);
  }

  .radio-btn-container {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    .small-input {
      width: 30%;
    }
  }

  .checkbox-container {
    label {
      margin: 8px 0 8px 8px;
    }

    .p-chkbox .p-chkbox-box {
      border: 1px solid $primaryBtnColor;

      .p-state-active {
        background-color: $primaryBtnColor;
      }
    }

    .p-chkbox-box.p-state-active:not(.p-state-disabled):hover {
      background-color: $primaryBtnColor;
      border-color: $primaryBtnColor;
      color: $white;
    }

    .p-chkbox-box:not(.p-state-disabled):hover {
      background: $disabledColor;
    }
  }

  .p-state-disabled,
  .p-widget:disabled {
    border: 1px solid $disabledColor;

    &::placeholder {
      color: $disabledColor;
    }
  }

  .p-calendar {
    font-family: 'Montserrat';
    // margin: 8px 0;
    width: 100%;

    .p-datepicker-trigger.p-button {
      color: $white;
    }
  }

  .p-dropdown,
  .p-multiselect {
    background: $white;
    border: 1px solid $inputBorderColor;
    border-radius: 5px;
    font-family: 'Montserrat';
    height: 40px;
    margin: 8px 0;
    // padding: 5px;
    padding: 0;
    width: 100%;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:disabled {
      border: 1px solid $disabledColor;

      &::placeholder {
        color: $disabledColor;
      }
    }

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      background: $defaultThemeColor;

      .p-dropdown-trigger-icon,
      .p-multiselect-trigger-icon {
        color: $white;
      }

      &:hover {
        background: $dropdownHoverColor;
      }
    }
  }

  a {
    color: $linkColor;
    font-family: 'Montserrat';

    &:active,
    &:focus,
    &:link {
      color: $linkColor;
      outline: none;
      text-decoration: none;
    }

    &:hover {
      color: $stateFocusBgColor;
      outline: none;
      text-decoration: none;
    }
  }

  .input-container {
    font-family: 'Montserrat';
    position: relative;
    width: 100%;

    .p-input-icon-left {
      display: inline-block;
      position: relative;
      width: 100%;

      i:first-of-type {
        color: #6C757D;
        left: .5rem;
        margin-top: -.5rem;
        position: absolute;
        top: 50%;
      }
    }

    .search-input {
      background: $white;
      border: 1px solid $inputBorderColor;
      border-radius: 5px;
      font-family: 'Montserrat';
      font-size: 14px;
      height: 40px;
      margin: 8px 0;
      padding: 5px;
      width: 100%;
    }

    .prefix-pad {
      padding-left: 2rem;
    }
  }

  .p-tabview-nav-container {
    .p-tabview-nav-content {
      .p-tabview-nav {
        // display: block;

        @media (max-width: 767px) {
          display: flex;
        }

        li {
          background-color: #FFF;
          border: 1px solid #D8D9FA;
          display: inline-block;
          margin: 0;
          padding: 0;
          transition: background-color .2s, box-shadow .2s;

          &:not(.p-highlight):not(.p-disabled):hover {
            .p-tabview-nav-link {
              background-color: #F4F5FA !important;
            }
          }

          &.p-tabview-ink-bar {
            display: none;
          }

          &.p-highlight {
            .p-tabview-nav-link {
              background: #FFF !important;
              border: 1px solid #D8D9FA;
              border-bottom-color: #568EE8;
              color: #568EE8 !important;
              font-weight: 700 !important;
            }
          }

          a {
            background-color: transparent;
            border: none;
            color: #333;
            display: block;
            float: none;
            font-size: 14px;
            font-weight: 400;
            padding: .571em 2em;
          }
        }
      }
    }
  }
}

.menu-title {
  display: none;
}

.p-dropdown-label-container {
  width: 100%;
}

// dialog input readonly style

.dialog-outer-container {
  .form-container-column .form-item .form-field input:read-only {
    background: #FFF !important;
    background-color: #EEEEEE96 !important;
    border: 1px solid #D4D6D9 !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {

  .admin-panel,
  .staff-panel {
    .page-container {
      .page-body {
        .phone-with-country-code {
          .phone-number-input {
            width: 70%;
          }

          .country-code-dropdown {
            width: 28%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 36px) and (max-width: 460px) {
  .menu-title {
    display: block;
  }
}

//  Icons style

// FullCalendar style

.fc-toolbar .fc-center div {
  display: flex;

  .fc-button {
    margin: 0 10px;
  }
}

body .fc-toolbar h2 {
  color: #707070;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  margin-top: 3px;
  text-align: left;
}

body .fc th {
  background-color: #FFF;
  border-bottom: 0;
  border-color: #D8D9FA;
  color: #707070;
  padding: .571em 1em;
}

.fc td {
  padding: 10px;
  text-align: center;
}

.fc-day {
  border: 1px solid #D8D9FA;
  text-align: center;
}

// td.fc-day.fc-past {
//   background-color: #EEEEEE;
//   cursor: default;
// }

.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  float: none !important;
  padding: 10px;
}

.fc-row .fc-content-skeleton {
  color: #707070;
  padding-bottom: 0;
  top: 8%;
}

div .fc td {
  border-color: #D8D9FA;
}

body .fc td.fc-head-container {
  border: 1px solid #D8D9FA;
}

.fc-event {
  cursor: pointer;
  font-size: .75rem;

  .fc-content {
    padding: 2px;
  }
}

@media screen and (min-width: 460px) and (max-width: 1200px) {
  .fc-event {
    font-size: .5rem;
  }
}

// .fc-event  {
//     -ms-overflow-style: none;  /* Internet Explorer 10+ */
//     scrollbar-width: none;  /* Firefox */
// }
// .fc-event ::-webkit-scrollbar {
//     display: none;  /* Safari and Chrome */
// }

.fc-event-time,
.fc-event-title {
  padding: 0 1px;
  white-space: nowrap;
}

.fc-title {
  font-weight: 600;
  white-space: normal;
}

.fc-unthemed td.fc-today {
  background: transparent !important;

  .fc-day-number {
    background: #568EE8;
    border-radius: 50%;
    color: #FFF;
    font-size: 12px;
    padding: 2px !important;
    position: relative;
    top: 1px;
  }
}

body .fc {
  .fc-toolbar {
    .fc-button {
      background-color: #FFF;
      border: 0;
      color: #707070;

      .fc-icon {
        font-size: 16px;
        vertical-align: top;
      }

      &:enabled:hover {
        background-color: #FFF;
        color: #707070;
      }
    }
  }
}

a.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end {
  margin: 1px;
  top: 2px;
}

a.fc-more {
  font-size: .85em;
  font-weight: 500;
  position: relative;
  top: 1px;
}

.fc {
  .fc-view-container {
    .fc-view {
      .fc-popover {
        .fc-header {
          background: #3D4246;
          color: #FFF;
        }

        .fc-widget-content .fc-event {
          border: 1px solid;
          border-radius: 4px;
          margin: 5px 0;
        }
      }
    }
  }
}

.fc-today {
  cursor: pointer;
}

.fc-more-cell {
  cursor: pointer;
}

.fc-bg table tr .fc-future {
  cursor: pointer !important;
}

// .fc-content-skeleton {
//   // cursor: pointer;
// }

.fc-content {
  cursor: pointer;
}

.fc-more-popover .fc-event-container {
  max-height: 210px;
  overflow-y: auto;
  padding: 10px;
}

.fc-unthemed .fc-popover {
  border-style: solid;
  border-width: 1px;
  width: 290px;
}

.fc-popover .fc-header .fc-title {
  color: #FFF;
}

.fc-more-popover {
  .fc-event .fc-content {
    text-align: center;
  }
}

body .fc .fc-toolbar .fc-left {
  .fc-monthnav-button {
    background: #568EE8;
    border: 0 !important;
    color: #FFF;
    font-size: 1rem;
    font-weight: 600;
    max-height: 40px;
    min-height: 40px;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    transition: all .5s ease;
  }
}

body .fc .fc-toolbar .fc-left .fc-monthnav-button:enabled {
  &:hover {
    background-color: $hoverColor;
    color: #FFF;
  }
}

p-calendar {
  .only-icon {
    .p-inputtext {
      display: none;
    }

    button.p-datepicker-trigger.p-calendar-button {
      border-radius: 4px !important;
    }
  }
}

body .fc .fc-toolbar .fc-right {
  .fc-today-button {
    background: #568EE8;
    border: 0 !important;
    color: #FFF;
    font-size: 1rem;
    font-weight: 600;
    max-height: 40px;
    min-height: 40px;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    transition: all .5s ease;
  }
}

body .fc .fc-toolbar .fc-right .fc-today-button:enabled {
  &:hover {
    background-color: $hoverColor;
    color: #FFF;
  }
}

.fc-future {
  cursor: pointer !important;
}

.fc-event-container {
  cursor: pointer !important;
}

.fc-day-grid-event .fc-content {
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.fc-title {
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fc-myCustomButton-button {
  box-shadow: none !important;
  cursor: default !important;
  outline: none !important;
}

.fc-myCustomButton-button:focus {
  box-shadow: none !important;
  outline: 0;
  outline-offset: 0;
}

.role-access-btn-wrapper {
  position: absolute;
  right: -210px;
  top: 30px;

  .p-button.large-btn {
    width: 190px;
  }
}
