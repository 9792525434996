app-header {
  background-color: $topBarBgColor;
  box-shadow: 0 7px 7px -6px rgba(0, 0, 0, .13);
  color: $topBarTextColor;

  a {
    color: $topBarTextColor;

    &:link,
    &:active,
    &:focus {
      color: $topBarTextColor;
      outline: none;
      text-decoration: none;
    }

    &.hamburger-menu {
      color: #B0BEC5;
      display: block;
      font-size: 24px;
      height: 61px;
      line-height: 61px;
      padding: 0 16px;

      &:hover {
        color: $defaultThemeColor;
      }
    }
  }
}

app-notification-strip {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.app-header {
  height: 48px;
}

.waffle-menu {
  height: 400px;
  overflow-y: auto;
  padding-top: 8px;
  width: 280px;

  .waffle-menu-box {
    text-align: center;

    a {
      color: #000;

      &:link {
        color: #000;
      }

      &:hover {
        color: #0073EA;
      }

      .menu-img {
        width: 75%;
      }

      .menu-lable {
        font-size: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.brand-div {
  float: right;

  @media screen and (max-width: 600px) {
    background-color: #FFF;
  }

  .brand {
    bottom: 15px;
    float: right;
    padding: 0;
    position: relative;

    p-multiselect {
      .p-multiselect {
        &:not(.ui-state-disabled) {
          border-color: #E1E3E7;
          color: #000;
        }
      }
    }
  }
}

.top-bar-item {
  float: left;

  &.notifications-bell {
    background-color: $defaultThemeColor;
    border: 1px solid $white;
    border-radius: 50px;
    height: 27px;
    margin: 11px 7px;
    padding: 5px 7px;
    position: relative;

    &::after {
      color: $black;
      content: '';
      display: inline-block;
      height: 60px;
      left: 55px;
      position: absolute;
      top: -16px;
      width: 1px;
    }

    .fa {
      font-size: 17px;
    }

    &:hover {
      background-color: $buttonHoverColor;
      cursor: pointer;

      .fa {
        color: $white;
      }
    }

    .new-notification-count {
      display: inline-block;
      font-size: 17px;
      line-height: 1.1;
      margin-left: 4px;
    }
  }

  &:hover {
    float: left;
  }
}

.nav-right-menu {

  .nav-notifications-icon {
    color: $topBarBgColor;
    line-height: 31px;
    padding: 0 10px;
  }

  .profile-menu-button {
    border-left: 2px solid $headerSplitBarColor;
    margin-left: 20px;

    a {
      border: 4px solid $white;
      color: $headerUserName;
      cursor: pointer;
      display: block;
      font-size: 16px;
      padding: 3px 15px;

      .user-photo {
        border: 1px solid $sideBarHoverBgColor;
        border-radius: 50%;
        display: block;
        float: left;
        height: auto;
        margin: 4px 12px 4px 4px;
        width: 39px;
      }

      .fa {
        margin-left: 8px;
      }

      &:hover {
        border-bottom: 4px solid $sideBarHoverBgColor;
      }
    }

    .ui-menuitem-link {
      padding: 10px 0;

      .fa {
        font-size: 20px;
        margin: 0 9px 0 28px;
      }

      &:hover {
        background: $sideBarHoverBgColor;
      }
    }
  }
}

.ui-menu {
  &.ui-menu-dynamic {
    display: none;
    padding: 0;
    position: absolute;
    right: 0;
    width: 223px;
    z-index: 100000;
  }

  &.ui-menuitem {
    clear: both;
    margin: 5%;
    padding: 0;
    width: 100%;
  }
}

.notification-try {
  p-overlayPanel {
    height: 350px;
    width: 350px;
  }

  .ui-overlaypanel {
    height: 350px;
    margin-top: 11px;
    top: 61px;
    width: 350px;
  }

  .ui-overlaypanel-content {
    background: $white;
    height: 350px;
    overflow: auto;
    padding: 0;
    width: 350px;

    hr {
      margin: 0;
    }

    .notification-show-more-link {
      padding: 20px;

      a {
        cursor: pointer;

        &:hover {
          color: $defaultThemeColor;
        }
      }
    }
  }
}

.header-strip {
  background-color: #FFF;
  height: 52px;
  max-height: 52px;
  min-height: 52px;
  padding: 5px 25px;
  position: fixed;
  right: 0;
  top: 0;
  transition: left .3s;
  width: 90%;
  z-index: 997;

  @media print {
    display: none;
  }

  @media screen and (max-width: 600px) {
    padding: 5px 10px;

    .mob-show {
      display: block;
    }
  }

  @media screen and (min-width: 600px) {
    .mob-show {
      display: none;
    }
  }

  .header-btn {
    border-radius: 50%;
    color: #FFF;
    float: left;
    font-size: 28px;
    height: 44px;
    line-height: 50px;
    margin-right: 8px;
    padding-left: 6%;
    text-align: center;
    transition: background-color .3s;
    width: 44px;

    i {
      color: #568EE8;
      font-size: 28px;
    }

    &:hover {
      background-color: #F2ECEC;

      i {
        color: $defaultThemeColor;
      }
    }
  }

  .header--menu {
    float: right;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      float: right;
      position: relative;

      a {
        border-radius: 50%;
        display: block;
        height: 44px;
        line-height: 44px;
        position: relative;
        text-align: center;
        transition: background-color .3s;
        width: 44px;

        i {
          color: #929AB9;
        }

        img {
          border-radius: 50%;
          height: 81%;
          margin-top: 4px;
          width: 82%;

        }

        & .notification-bell {
          width: 50%;
        }

        & .notification-oval {
          background: #F00;
          border: 2px solid #FFF;
          border-radius: 50%;
          height: 22px;
          left: 21px;
          position: absolute;
          top: 6px;
          width: 22px;

          .notification-count {
            color: #FFF;
            font-size: 12px;
            font-weight: 600;
            margin: auto;
            position: relative;
            top: -14px;
          }
        }

        &:hover {
          background-color: #F2ECEC;
        }
      }

      &.toggle-menu {
        ul {
          display: grid;
        }
      }

      ul {
        animation-duration: .3s;
        background-color: #FFF;
        border: 1px solid #E0E0E0;
        border-radius: 3px;
        box-shadow: 0 2px 10px 0 rgba(0, 3, 6, .16);
        display: none;
        list-style-type: none;
        margin: 0;
        min-width: 171px;
        padding: 8px 12px;
        position: absolute;
        right: -18px;
        top: 55px;

        &::before {
          border-bottom-color: #E0E0E0;
          border-color: rgba(216, 216, 220, 0);
          border-width: 10px;
          margin-left: -10px;
        }

        &::after {
          border: solid transparent;
          bottom: 100%;
          content: ' ';
          height: 0;
          left: 210px;
          pointer-events: none;
          position: absolute;
          width: 0;
        }

        a {
          border-radius: 3px;
          box-sizing: border-box;
          color: #727272;
          display: block;
          line-height: 28px;
          padding: 8px 12px;
          position: relative;
          text-align: left;
          transition: background-color .3s;
          width: 100%;

          i {
            color: #727272;
            margin-right: 8px;
          }

          span {
            vertical-align: middle;
          }
        }
      }
    }
  }
}

@keyframes fade-opacity {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fade-opacity {
  animation-name: fade-opacity;
}

.closed {
  .header-strip {
    left: 0;
    transition: left .3s;
  }
}

.p-sidebar {
  .p-sidebar-header {
    &::before {
      content: 'Notifications';
      font-size: 16px;
      font-weight: 600;
      left: 16px;
      position: absolute;
    }

    .read-all-notifications {
      color: #0073EA;
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      padding-right: 5px;
      text-align: right;
      text-decoration: underline;
      width: 100%;

      &:hover {
        color: #007AD9;
      }
    }
  }

  .p-sidebar-content {
    padding: 0;

    .notification-list {
      border-top: 1px solid #DDD;

      .deny {
        pointer-events: none;

        &:hover {
          cursor: not-allowed;
        }
      }

      .notification-box {
        border-bottom: 1px solid #DDD;
        padding: 16px;

        .notification-title {
          font-size: 14px;
          font-weight: 600;
        }

        .notification-date {
          font-size: 13px;
          font-weight: normal;
          padding: 4px 0;
        }

        &:hover {
          cursor: pointer;
        }

        &.read {
          background-color: #EDEEF2;
        }

        &.unread {
          background-color: #FFF;
        }
      }
    }
  }

}
