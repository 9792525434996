.mi-grid {
  background: $white;
  margin: 0;
  margin-top: -82px;
  padding: 40px 10px;
  padding-top: 20px;
}

.no-records {
  align-items: center;
  display: flex;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  height: 60px;
  justify-content: center;
  margin: 30px;
  margin-left: 0;
  text-align: center;
  width: 100%;

  .center-div {
    text-align: center;
    width: 100%;

    &.normal-div {
      position: unset;
    }
  }
}

.grid-menu {
  background: $white;
  border: 2px;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
  display: none;
  font-family: Montserrat;
  margin-left: -158px;
  margin-top: -10px;
  overflow: hidden;
  position: absolute;
  width: 150px;
  z-index: 99999;

  .grid-menu-item {
    div {
      color: #000;
      padding: 10px;

      &:hover {
        background: $btnBgColor;
        color: #FFF;
      }
    }
  }
}

.action-icon {
  background: url('/assets/images/gridGroupIcon.png') 6px 0 no-repeat;
  cursor: pointer;
  float: right;
  height: 25px;
  margin: 0;
  padding: 10px;
}

.search-and-export-data-panel {
  input {
    &.grid-search-bar {
      background-color: $white;
      border: solid 1px #000;
      border-radius: 2px;
      font-family: Montserrat;
      font-size: 15px;
      height: 36px;
      opacity: .45;
      text-indent: 20px;
      width: 250px;

      &.top {
        margin-top: 0;
      }

      &.bottom {
        margin-top: 15px;
      }
    }
  }

  label {
    &.input-icon {
      left: -22px;
      position: relative;
    }

    &.search-icon {
      bottom: 25px;
      left: 4px;
      position: relative;
    }
  }

  .export-button {
    float: right;
    height: 60px;
    left: 17px;
    margin-left: calc(100% - 106px);
    position: relative;
    z-index: 1;

    &.top {
      margin-bottom: 15px;
    }

    &.bottom {
      margin-top: 15px;
    }

    .header-action-btn {
      border: 1px solid $defaultThemeColor;
      border-radius: 2px;
      color: $defaultThemeColor;
      cursor: pointer;
      float: right;
      font-family: Montserrat;
      font-size: 18px;
      line-height: 22px;
      margin: 0 2px;
      padding: 5px 6px;
      text-align: center;
      vertical-align: middle;

      &:hover {
        border: 1px solid $defaultThemeColor;
      }
    }
  }

}

.reports-container {
  .search-and-export-data-panel {
    .export-button {
      bottom: -40px;
    }
  }
}

.p-table {
  .p-datatable-tbody {
    tr {
      &.p-state-highlight {
        background-color: #FFF;
        color: #444;
      }
    }
  }
}


p-table {

  .p-datatable-table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    .p-sortable-column {
      &.p-highlight {
        background-color: transparent;
        color: #707070;

        &:hover {
          background-color: transparent;
          color: #707070;

          .p-sortable-column-icon {
            color: #707070;
          }
        }

        .p-sortable-column-icon {
          color: #707070;

          &:hover {
            background-color: transparent;
            color: #707070;
          }
        }
      }
    }

    .p-datatable-thead {
      display: table-row-group;

      tr {
        display: table-row;

        td {
          background: #F5F5F6;
          border: 1px solid #D8D9FA;
          border-bottom: 0;
          color: #707070;
          display: table-cell;
          font-size: 14px;
          font-weight: 600;
          padding: 10px;
          text-align: left;
        }
      }
    }

    .p-datatable-tbody {
      display: table-row-group !important;

      tr {
        display: table-row !important;
        height: 40px;

        td {
          background: #FFF;
          border: 1px solid #D8D9FA !important;
          border-width: 1px;
          color: #707070;
          display: table-cell !important;
          font-size: 15px;
          font-weight: 400;
          padding: 0 10px;
          text-align: left;
        }
      }

      .ui-widget-header {
        height: 30px;
      }
    }

    .p-datatable-tfoot {
      background-color: transparent;
      display: table-row-group !important;

      tr {
        display: table-row !important;
        height: 40px;

        td {
          display: table-cell !important;

          @media (max-width: 900px) {
            display: table-cell !important;
          }

          &:has(.footer-info) {
            padding: 0;
          }
        }
      }
    }
  }

  #showFooter {
    border: 1px solid #DDD;
    text-align: left;

    @media (max-width: 900px) {
      border: 1px solid #DDD;
      display: table-cell !important;
      text-align: left;
    }
  }


  .p-paginator-bottom {
    background: transparent;
    border: 0;
    margin-top: 10px;
  }

  .p-table {
    font-family: 'Montserrat';

    div {
      &.p-table-wrapper {
        border-bottom: 1px solid #DDD;
      }
    }

    tfoot {
      &.p-table-tfoot {
        td {
          padding: 0;
        }
      }
    }

    .p-table-sort-icon {
      display: none;
    }


    .p-sortable-column {
      &:not(.p-state-highlight) {
        &:hover {
          background: rgba(255, 255, 255, .2);
          color: $black;

          .p-sortable-column-icon {
            color: inherit;
          }
        }
      }

      &.p-state-highlight {
        background-color: $white;
        color: $black;

        .p-sortable-column-icon {
          color: inherit;
        }
      }



      &:focus {
        box-shadow: none;
      }
    }

    .p-datatable-tbody {
      tr {

        td {
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
          color: #32455F;
          font-family: Montserrat;
          font-size: 15px;
          letter-spacing: $fontNormal;
          line-height: $fontNormal;
          padding: .571em .857em;

          .prime-btn {
            &.p-state-default {
              font-size: 13px;
              font-weight: 600;
              padding: 8px 23px;
            }
          }

          .active {
            color: $activeStatus;
            width: 50px;
          }

          .inactive {
            color: $inactiveStatus;
            width: 64px;
          }

          .ended {
            color: $endedStatus;
            width: 64px;
          }
        }
      }
    }

  }
}

.mi-grid-component {
  .p-datatable-tbody {
    .active-item-square {
      background-color: #00C59D;
      height: 9px;
      margin: auto;
      width: 9px;

      @media (min-width: 360px) and (max-width: 400px) {
        margin: 4px 6px;
        margin-left: 0;
      }
    }

    .delete-item-square {
      background-color: #E36161;
      height: 9px;
      margin: auto;
      width: 9px;

      @media (min-width: 360px) and (max-width: 400px) {
        margin: 4px 6px;
        margin-left: 0;
      }
    }

    .expired-item-square {
      background-color: #F4B600;
      height: 9px;
      margin: auto;
      width: 9px;

      @media (min-width: 360px) and (max-width: 400px) {
        margin: 4px 6px;
        margin-left: 0;
      }
    }

    .equipment-circle {
      border-radius: 50%;
      height: 12px;
      margin: 3px 5px 5px 0;
      width: 12px;
    }

    .planned-item-square {
      background-color: #6495ED;
      height: 9px;
      margin: 5px 6px;
      width: 9px;

      @media (min-width: 360px) and (max-width: 400px) {
        margin: 4px 6px;
        margin-left: 0;
      }
    }

    .p-chkbox {
      .p-chkbox-box.p-state-active {
        &:not(.p-state-disabled) {
          &:hover {
            background-color: #007AD9 !important;
            border-color: #007AD9 !important;
            color: #FFF;
          }
        }
      }
    }

    .p-chkbox {
      .p-chkbox-box {
        &:not(.p-state-disabled) {
          &:hover {
            border-color: #A6A6A6 !important;
          }
        }
      }
    }

    >tr {
      >td {
        // font-size: smaller;
        text-overflow: ellipsis;
        white-space: nowrap;

        .p-column-title {
          min-width: 55%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 55%;
        }
      }
    }
  }
}

.manage-franchise,
.grid-lines {
  .custom-grid {
    p-table {
      .p-table-thead {

        tr {
          border: 1px solid #F4F5FA;

          th {
            background: #F4F5FA;
            border: 1px solid #F4F5FA;
            padding: 20px 16px;

            &:hover {
              background: #F4F5FA;
            }
          }
        }
      }

      .p-datatable-tbody {

        tr {
          border: 1px solid #F4F5FA;

          td {
            border: 1px solid #F4F5FA;
          }
        }
      }
    }
  }

}

.manage-franchise,
.grid-lines {
  .custom-grid p-table .p-datatable-tbody tr td {
    padding: 0 .857em;
  }
}

.manage-franchise,
.grid-lines {
  .mi-grid {
    margin-top: 0;
  }
}

p-table {
  .p-datatable-table {
    .p-datatable-tbody {
      tr {
        td:has(.no-records) {
          border-left: 0;
          border-right: 0;
        }
      }
    }
  }
}

.action-icon1 .edit-pencil-icon {
  margin-top: 12px !important;

  &.disabled {
    opacity: .4;

    &:hover {
      // background-color: #ACB4D6;
      // border-radius: 5px;
      cursor: not-allowed !important;
    }
  }
}

.disabled {
  opacity: .5;
  
  &:hover {
    cursor: not-allowed !important;
  }
}

.image-div {
  padding: 7px;
  text-align: center;

  .menu-image-list {
    vertical-align: middle;
    width: 2em;
  }
}

.right-align {
  padding-right: 6px;
  text-align: right;
}
