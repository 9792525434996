// Colors
$white: #FFF;
$black: #000;
$defaultThemeColor: #568EE8;
$secondaryThemeColor: #F5F7F9;
$inputLabelTextColor: #707070;
$inputBorderColor: #D4D6D9;
$gridBorderColor: #D8D9FA;
$pageBgColor: #EDEEF2;
$primaryBtnColor: #568EE8;
$secondaryBtnColor: #929AB9;
$paginationHoverColor: #E0E0E0;
$disabledColor: #ACB4D6;
$sidebarHoverColor: #EBEFFF;
$hoverColor: #5DB3B5;
$dropdownHoverColor: #5082D3;
