.franchise {
  .signin-box-container {
    background: $sidebarBgColor;
    display: table;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .signin-box-inner-container {
      display: inline-flex;
      vertical-align: middle;

      .form-field {
        box-sizing: border-box;
        padding-bottom: 0;
        padding-top: 0;
      }

      .company-logo {
        background-color: transparent;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 45px 13px 4px;
        text-align: left;

        img {
          background-color: transparent;
          width: 71px;
        }
      }

      .signin-box {
        margin: initial;
        position: relative;
      }

      .sign-in-eye {
        position: absolute;
        right: 5px;
        top: 17px;
      }
    }
  }

  .ui-panel {
    // background-color: $white;
    // border-bottom-left-radius: 8px;
    // border-bottom-right-radius: 8px;
    height: 325px;

    .ui-panel-titlebar {
      text-align: center;
    }
  }
}

.form-errors {
  div {
    font-size: 14px;
  }
}

.page-layout {
  .signin-box {
    .form-container {
      .form-field {
        input[type='text'] {
          background: #F8F9FA 0% 0% no-repeat padding-box;
          margin: 8px 0;
        }

        input[type='password'] {
          background: #F8F9FA 0% 0% no-repeat padding-box;
          margin: 8px 0;
        }
      }
    }
  }
}

@media screen and (max-width: 40em) {
  .signin-box-container {
    .signin-box-inner-container {
      .signin-box {
        left: .75%;
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .franchise {
    .signin-box-container {
      background: #F5F7F9;

      .signin-box-inner-container {
        background: #F5F7F9;
        display: block;

        .signin-image-wrapper {
          display: none;
        }

        .signin-box {
          left: 0;
          margin: 0 auto;
          padding-left: .5rem;

          .ui-panel {
            background: #F5F7F9;

            .ui-panel-content {
              background: #F5F7F9;
            }
          }

          .company-logo {
            padding-bottom: 10px;
            text-align: center;

            img {
              margin-left: 0;
            }
          }
        }

        .sign-in-eye {
          top: 26px;
        }
      }
    }
  }

  .page-layout {
    .form-container {
      justify-content: center !important;

      .login-btn-wrapper {
        text-align: center;
      }

      .form-field {
        input[type='text'] {
          background-color: $white;
          height: 55px;
        }

        input[type='password'] {
          background-color: $white;
          height: 55px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .franchise {
    .signin-box-container {
      align-items: center;
      background: #F5F7F9;
      display: flex;

      .signin-box-inner-container {
        display: block;

        .signin-image-wrapper {
          display: none;
        }

        .signin-box {
          left: 0;
          padding-left: .5rem;
          padding-right: .5rem;

          .company-logo {
            img {
              margin-left: 0;
            }
          }

          .forgot-password-link {
            font-size: 14px;
          }

          .login-btn {
            font-size: 20px;
            line-height: normal;
          }
        }

        .sign-in-eye {
          top: 17px;
        }
      }
    }
  }

  .page-layout {
    .form-container {
      .form-field {
        input[type='text'] {
          background-color: $white;
          height: 38px;
        }

        input[type='password'] {
          background-color: $white;
          height: 38px;
        }
      }
    }
  }
}
