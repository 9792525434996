@import '../../admin/admin-theme.scss';

.page-layout {
  .admin-layout {
    .dashboard {
      .middle-div {
        background-color: $white;
        border-radius: 8px;
        padding-left: 0;
        padding-right: 0;

        .header-div {
          background-color: $white;
          border-radius: 5px;
          margin: 2%;
          text-align: center;

          .export-header-label {
            color: #283142;
            font-size: 22px;
            font-weight: 600;
          }
        }

        .export-div {
          background-color: #29ABE2;
          // border: 1px solid green;
          border-radius: 5px;
          cursor: pointer;
          height: 55px;
          margin: 25px 2%;
          max-height: 55px;

          .icon-div {
            padding-top: .3rem;
          }

          .label-div {
            padding-top: .2rem;
          }

          .export-label {
            color: $white;
            cursor: pointer;
            font-size: 16px;
            font-weight: 700;
          }

          a {
            width: 100%;
          }
        }
      }
    }
  }
}

body .p-panelmenu {
  .p-panelmenu-content {
    .p-menuitem {
      .p-menuitem-link {
        color: $sidebarColor;

        &.ui-menuitem-link-active {
          border-right: 4px solid $sidebarActiveColor;

          .p-menuitem-text {
            font-weight: 600;
          }
        }

        &:focus {
          box-shadow: none;
        }

        &:active {
          border-right: 4px solid $sidebarActiveColor;
        }

        &:hover {
          color: $sidebarActiveColor;

          .p-menuitem-text {
            color: $sidebarActiveColor;
          }
        }

        &:not(.ui-state-disabled):hover {
          .p-menuitem-text {
            color: $sidebarActiveColor;
            font-weight: 600;
          }
        }

        .p-menuitem-icon {
          // padding-left: 40px;
          height: 20px;
          left: 14px;
          padding-left: 32px;
          position: relative;
          width: 20px;

          &.sidebar-no-reply-icon {
            left: 12px;
          }
        }

        .p-menuitem-text {
          color: $sidebarColor;
        }
      }
    }
  }

  .p-panelmenu-header {
    &.p-state-active>a {
      &:hover {
        color: $sidebarActiveColor;

        .p-panelmenu-icon {
          color: $sidebarActiveColor;
        }
      }
    }

    &:not(.p-state-active)>a:hover {
      .p-panelmenu-icon {
        color: $sidebarActiveColor;
      }
    }
  }
}

// open

.admin-panel,
.staff-panel {
  .sidebar {
    background-color: $sidebarBgColor;
    height: 97vh;
    position: fixed;
    transition: width .3s;
    width: 5%;
    z-index: 999;

    &.franchise-sidebar {
      box-shadow: 0 5px 15px rgba(0, 0, 0, .3);
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      position: fixed;
      top: 0;

      .company-logo {
        background-color: transparent;
        padding: 8px 8px 0;
        text-align: center;
        width: 5em;

        img {
          cursor: pointer;
          margin-left: -15px;
          width: 75%;

          @media print {
            display: none;
          }
        }
      }

      &::-webkit-scrollbar {
        height: .4em;
        width: .6em;
      }

      &::-webkit-scrollbar-track {
        border-radius: 3px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #D1C7C7;
        border-radius: 3px;
        outline: 1px solid #708090;
      }
    }

    &:hover {
      width: 14%;
    }
  }

  .header-strip {
    left: 0;
    transition: left .3s;
    width: 100%;
  }
}

// closed

.closed {

  .admin-panel,
  .staff-panel {
    .sidebar {
      &.franchise-sidebar {
        height: 100%;
        left: 0;
        max-width: 68px;
        position: fixed;
        top: 0;
        width: 4%;

        @media screen and (max-width: 600px) {
          width: 0%;
        }

        .p-menuitem-text {
          display: none;
        }

        @media print {
          display: none;
        }
      }
    }

    .header-strip {
      box-shadow: 0 3px 6px #00000029;
      left: 0;
      transition: left .3s;
      width: 100%;
    }

    .main-panel {
      background: $pageBgColor;
      left: 4%;
      min-width: 96%;
      padding: 17px;
      position: absolute;
      top: 60px;
      width: 96%;

      @media screen and (max-width: 600px) {
        left: 0%;
        width: 100%;
      }
    }
  }
}

.closed .staff-panel .main-panel {
  padding: 0;
}


.staff-panel {
&.notification-strip {
    .main-panel {
      margin-top: 2.3em;
    }
  }
}

.admin-panel {
    &.notification-strip {
      .page-header {
        margin-top: 25px;
      }
    }
  .bread-crumb {
    a {
      color: $inputLabelTextColor;
      cursor: pointer;
      font-size: 13px;
      margin: 0 3px;
    }
  }

  .event-page,
  .send-mail-page,
  .add-event-dialog,
  .task-page {
    .p-calendar .p-datepicker-trigger.p-button {
      margin-top: 8px;
      position: relative; //absolute;
      right: 2px;
    }
  }
}

.humberger-wrapper {
  align-items: center;
  display: inline-flex;
  padding-left: 25px;

  img {
    width: 38px;
  }
}

.icon {
  background-position: 7px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 14px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

// action icons

.deactivate-icon {
  background-image: url('/assets/images/action_icons/delete_icon.png');
  background-position: 5px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 14px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.activate {
  background-image: url('/assets/images/admin-icons.png');
  background-position: -81px -189px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 14px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.orders-icon {
  &.details-icon {
    background-image: url('/assets/images/action_icons/info.png');
    background-position: 6px 6px;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 30px;
    margin-bottom: 6px;
    margin-top: 14px;
    width: 30px;

    &:hover {
      background-color: #ACB4D6;
      border-radius: 5px;
    }
  }
}

.menu-extra-itms-icon {
  background-image: url('/assets/images/new_icons/extras_items_category.png');
  background-position: 6px 6px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 10px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.qr-code-icon {
  background-image: url('/assets/images/action_icons/qr-code.png');
  background-position: 6px 6px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.details-icon {
  background-image: url('/assets/images/action_icons/info.png');
  background-position: 6px 6px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.edit-pencil-icon {
  background-image: url('/assets/images/action_icons/edit.png');
  background-position: 6px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.create-openshift-icon {
  background-image: url('/assets/images/action_icons/create_open_shift.png');
  background-position: 4px !important;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;
  opacity: 0.9;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.delete-openshift-icon {
  background-image: url('/assets/images/action_icons/delete_open_shift.png');
  background-position: 4px !important;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;
  opacity: 0.9;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.set-as-primary-icon {
  background-image: url('/assets/images/action_icons/admin-icons.png');
  background-position: -297px -34px;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-bottom: 6px;
  margin-top: 12px;
	height: 30px;
	width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.not-allowed {
  cursor: not-allowed;
}

.menu-edit-icon {
  background-image: url('/assets/images/action_icons/edit.png');
  background-position: 6px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 25px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 25px;
}

.orders-icon {
  background-image: url('/assets/images/action_icons/order.png');
  background-position: 5px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.view-details-icon {
  background-image: url('/assets/images/action_icons/eye.png');
  background-position: 3px 6px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.event-hold-icon {
  background-image: url('/assets/images/action_icons/hold.png');
  background-position: 6px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }

  &.deactivate {
    background-image: url('/assets/images/action_icons/delete_icon.png');
    background-position: 5px 5px;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 30px;
    margin-bottom: 6px;
    margin-top: 12px;
    width: 30px;

    &:hover {
      background-color: #ACB4D6;
      border-radius: 5px;
    }
  }
}

.event-open-icon {
  background-image: url('/assets/images/action_icons/resume.png');
  background-position: 7px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }

  &.activate {
    background-image: url('/assets/images/action_icons/admin-icons.png');
    background-position: -81px -189px;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 30px;
    margin-bottom: 6px;
    margin-top: 12px;
    width: 30px;

    &:hover {
      background-color: #ACB4D6;
      border-radius: 5px;
    }
  }
}

.opt-in {
  background-image: url('/assets/images/action_icons/opt-in.png');
  background-position: 8px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.opt-out {
  background-image: url('/assets/images/action_icons/opt-out.png');
  background-position: 8px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.event-file-download {
  color: #568EE8;
  cursor: pointer;
  font-size: 19px;
  height: 30px;
  margin: 11px 5px 5px;
  padding: 6px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }

}

.event-order-report-excel {
  background-image: url('/assets/images/action_icons/report.png');
  background-position: 7px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.icon-merge {
  background-image: url('/assets/images/action_icons/merge.png');
  background-position: 7px 6px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.event-clone {
  background-image: url('/assets/images/action_icons/clone.png');
  background-position: 6px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.invoice-clone {
  background-image: url('/assets/images/action_icons/invoice_clone.png');
  background-position: 5px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.invoice-refund {
  background-image: url('/assets/images/action_icons/invoice_refund.png');
  background-position: 5px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}
.payment-paid {
  background-image: url('/assets/images/action_icons/invoice_paid.png');
  background-position: 5px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.payment-unpaid {
  background-image: url('/assets/images/action_icons/invoice_upaid.png');
  background-position: 5px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.copy-clone {
  background-image: url('/assets/images/action_icons/copy1.png');
  background-position: 6px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}


.event-delete-icon {
  background-image: url('/assets/images/action_icons/delete_icon.png');
  background-position: 5px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.convert-to-invoice-icon {
  background-image: url('/assets/images/action_icons/green_check_icon.png');
  background-position: 6px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 27px;
  margin-bottom: 4px;
  margin-top: 14px;
  scale: 1.2;
  width: 27px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
} 

.email-icon {
  background-image: url('/assets/images/action_icons/email_icon.png');
  background-position: 5px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.settings {
  background-image: url('/assets/images/settinngs_icon.png');
  background-position: 7px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 14px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.manage-icon-menu {
  background-image: url('/assets/images/settinngs_icon.png');
  background-position: 5px 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 14px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.save-icon {
  background-image: url('/assets/images/action_icons/save.png');
  background-position: 7px 7px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 1px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.cancel-icon-menu {
  background-image: url('/assets/images/action_icons/cancel_icon.png');
  background-position: 4.5px 4px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 0;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.event-restore {
  background-image: url('/assets/images/action_icons/reset-icon.png');
  background-position: 6px 5px;
  background-repeat: no-repeat;
  background-size: auto;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-left: 0;
  margin-top: 14px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .admin-panel {
    .sidebar:hover {
      width: 16%;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .closed {

    .admin-panel,
    .staff-panel {
      .sidebar {
        &.franchise-sidebar {
          width: 5.5%;
        }
      }
    }
  }

  .admin-panel {
    .sidebar:hover {
      width: 22%;
    }
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .closed {

    .admin-panel,
    .staff-panel {
      .main-panel {
        min-width: 91%;
        width: 91%;

        .main-container {
          margin-left: 22px;
        }
      }

      .sidebar {
        &.franchise-sidebar {
          width: 9%;
        }
      }
    }
  }

  .admin-panel {
    .sidebar {
      &:hover {
        width: 22%;
      }
    }
  }

  .main-container {
    .page-layout {
      .search-and-export-data-panel {
        .export-button {
          bottom: -14px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .main-panel {
    left: 3%;
    min-width: 94%;
    padding: 8px;
    width: 94%;

    .sidebar {
      width: 22%;
    }
  }

  .closed {

    .admin-panel,
    .staff-panel {
      .header-strip {
        left: 0;
        padding: 10px;
      }

      .main-panel {
        left: 0;
        min-width: 100%;
        width: 100%;

        .main-container {
          margin-left: 0;
        }
      }

      .sidebar {
        &.franchise-sidebar {
          -webkit-transition: width .3s;
          min-width: 0;
          overflow: auto;
          transition: width .3s;
          width: 0;
          z-index: 1;
        }
      }
    }
  }

  .admin-panel {
    .sidebar {
      width: 22%;
    }
  }

  body {
    .main-container {
      margin-left: 0;
    }
  }
}

@media screen and (min-width: 992px) {
  .humberger-wrapper {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .humberger-wrapper {
    padding-left: 0; //10px;
  }

  .admin-panel,
  .staff-panel {
    .sidebar {
      &.franchise-sidebar {
        -webkit-transition: width .3s;
        left: 0;
        overflow: auto;
        transition: width .3s;
        width: 80%;
      }
    }

    .main-panel {
      margin-left: 0;
    }
  }

  .closed {
    .admin-panel {
      .sidebar {
        &.franchise-sidebar {
          -webkit-transition: width .3s;
          min-width: 0;
          transition: width .3s;
          width: 0;
          z-index: 1;
        }
      }

      .header-strip {
        left: 0;
        padding: 10px;
      }

      .main-panel {
        left: 0%;
        min-width: 94%;
        padding: 8px;
        width: 100%;
      }
    }
  }
}

body {
  .customer-confirmation-dailog {
    .p-confirm-dialog {
      width: 30em;
    }
  }

  .custom-confirmation-style {
    .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
      margin: 1rem 0;
    }

    .p-dialog {
      .p-dialog-titlebar {
        background-color: #F44B4B;
        text-align: center;

        .p-dialog-title {
          color: $white;
          font-size: 16px;
        }

        .p-dialog-titlebar-icon {
          color: $white;
        }
      }

      .p-dialog-content {
        // height: 8em;
        align-items: flex-start;
        padding-left: 2em;
        padding-right: 2em;
        padding-top: 1em;
      }

      .p-dialog-footer {

        // position: relative;
        border: 0;
        bottom: 19px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        padding-bottom: 25px;
        text-align: center;

        .p-button {
          background: #90C590;
        }

        .p-confirm-dialog-reject {
          &.p-button {
            background: #29ABE2;
          }
        }
      }
    }
  }

  .assign-asset {
    .p-dialog {
      background: #FFF;
      height: 80%;
      min-height: 65%;
      position: absolute;
      top: 10%;

      .p-dialog-content {
        .page-buttons {
          bottom: 10px;
          position: absolute;
          right: 3%;
        }
      }
    }
  }

  .menu-image {
    &.p-dialog {
      background: #FFF;
      padding: 0 0 15px;
      top: -5em;

      .p-dialog-content {
        overflow: unset;

        .menu-content {
          .images-content {
            max-height: 27em;
            overflow: auto;
          }
        }

        .page-buttons {
          bottom: 10px;
          position: absolute;
          right: 3%;
        }
      }
    }
  }

  &.extra-menusection {
    &.p-dialog {
      top: -10em;
    }
  }
}

.activateNotifaction {
  background-position: -81px -189px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 14px;
  padding-left: 4px;
  padding-top: 10px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.event-delete-icon {
  background-image: url('/assets/images/action_icons/delete-icon.svg');
  background-position: 7.5px 4px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}

.event-reset {
  background-image: url('/assets/images/action_icons/reset-icon-two.png');
  background-position: 6px 6px;
  background-repeat: no-repeat;
  background-size: auto;
  cursor: pointer;
  height: 30px;
  margin-bottom: 6px;
  margin-left: 0;
  margin-top: 14px;
  width: 30px;

  &:hover {
    background-color: #ACB4D6;
    border-radius: 5px;
  }
}
