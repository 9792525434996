/// sidebar master

.company-logo {
  background-color: $defaultThemeColor;
  padding: 8px 8px 0;
  text-align: center;

  img {
    cursor: pointer;
    width: 77px;
  }
}

.sidebar {
  .p-menuitem-text {
    font-size: 14px;
  }

  .p-widget-content {
    background-color: $sidebarBgColor;
    border: 0 none;

    .p-menuitem-link {
      border-left: 3px solid $sidebarBgColor;
      color: $sidebarColor;
      height: 40px;
      padding: 8px 4px 3px;
      white-space: nowrap;

      &:hover {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        color: $sidebarMenuHoverTextColor;
      }

      &:active {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 0 solid $defaultThemeColor;
        box-shadow: none;
        color: $sidebarMenuHoverTextColor;
        padding: 8px 4px 3px;
      }

      &:focus {
        box-shadow: none;
      }

      .p-menuitem-text {
        color: $sidebarColor;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .p-menuitem {
      &:not(.p-state-active) {
        &:hover {
          //background-color: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          color: $sidebarActiveColor;
        }

        &:active {
          //background-color: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          border-left: 3px solid $defaultThemeColor;
          color: $sidebarColor;
        }
      }

      .p-state-active {
        //background: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 3px solid $defaultThemeColor;

        .p-menuitem-text {
          color: $sidebarColor;
          font-size: 12px;
        }
      }
    }
  }

  .p-panelmenu {
    .fa {
      color: $sidebarIconColor;
      font-size: 20px;
      margin-right: 12px;
    }

    .p-panelmenu-content {
      border: 0;
    }

    .p-panelmenu-header {
      &.p-highlight:not(.p-disabled)>a:hover {
        background-color: #EBEFFF;
        border: 0;
        color: #568EE8;
      }

      &:not(.p-highlight):not(.p-disabled)>a:hover {
        background-color: #EBEFFF;
        color: #568EE8;
      }

      .p-menuitem {
        .p-menuitem-link {
          &:focus {
            box-shadow: none;
          }
        }
      }

      a {
        background-color: transparent;
        border: 0;
        border-left: 9px solid transparent;
        color: #707070;
        color: $sidebarMenuTextColor;
        font-weight: 500;
        padding: 20px 0 15px 16px;
        white-space: nowrap;

        &:focus {
          box-shadow: none;
        }
      }

      &:hover {
        background-color: #EBEFFF;
        border: 0;
        border-right: 4px solid $sidebarActiveColor;
      }

      &:active {
        a {
          color: $blue;
        }
      }

      .p-state-active {
        background-color: $sideBarHoverBgColor;
        border-color: $sideBarHoverBgColor;
        border-left: 9px solid $sidebarActiveColor;
        color: $sidebarActiveColor;
      }

      .p-panelmenu-headerlink-hasicon {
        &.p-state-active {
          background: $sideBarHoverBgColor;
          border-color: $sideBarHoverBgColor;
          box-shadow: inset 3 0 0 0 rgba(236, 29, 37, 1);
          color: $white;
        }

        &:hover {
          background-color: $sideBarHoverBgColor;
          border-top: 0;
          box-shadow: inset 3px 0 0 0 rgba(236, 29, 37, 1);
        }
      }
    }
  }

  a {
    color: $sidebarMenuHoverTextColor;
  }

  .p-state-default {
    background: $sidebarBgColor;
    color: $sidebarMenuTextColor;
  }

  .fa-caret-down,
  .fa-caret-right {
    &::before {
      content: '';
    }
  }

  .p-panelmenu-icon {
    display: none;
    float: right;
    margin-right: 10px;
    margin-top: 4px;

    &.fa {
      font-size: 18px;
    }
  }
}

body {
  .p-panelmenu {
    .p-panelmenu-header {
      &.p-state-active>a {
        .p-panelmenu-icon {
          color: #848484;
        }
      }

      & :hover {
        .p-panelmenu-icon {
          color: #848484;
          display: inline;
        }

        .p-icon-wrapper {
          display: inline;
        }
      }

      .p-icon-wrapper {
        display: none;
      }

      .p-panelmenu-header-content {
        background: transparent;
        border: none;
      }
    }
  }
}

.close-icon-wrapper {
  display: inline-block;
  position: relative;
  right: -135px;
  top: -30px;
  z-index: 99;

  .pi-times {
    font-size: 18px;
  }
}

@media screen and (min-width: 1600px) {
  .left-panel {
    width: 15%;
  }

  .right-panel {
    width: 85%;
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .left-panel {
    width: 20%;
  }

  .right-panel {
    width: 80%;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .left-panel {
    width: 22%;
  }

  .right-panel {
    width: 78%;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .closed {
    .admin-panel {
      .main-panel {
        min-width: 91%;
        width: 91%;

        .main-container {
          margin-left: 22px;
        }
      }

      .sidebar {
        &.franchise-sidebar {
          width: 9%;
        }
      }
    }
  }

  .admin-panel {
    .sidebar {
      &:hover {
        width: 22%;
      }
    }
  }

  .main-container {
    .page-layout {
      .search-and-export-data-panel {
        .export-button {
          bottom: -14px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  body {
    .main-container {
      margin-left: 0;
    }
  }

  .main-panel {
    left: 3%;
    min-width: 94%;
    padding: 8px;
    width: 94%;

    .sidebar {
      width: 22%;
    }
  }

  .closed {
    .admin-panel {
      .main-panel {
        left: 0%;
        min-width: 100%;
        width: 100%;

        .main-container {
          margin-left: 0;
        }
      }

      .sidebar {
        &.franchise-sidebar {
          min-width: 0;
          width: 0;
          z-index: 1;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .humberger-wrapper {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .admin-panel {
    .sidebar {

      &.franchise-sidebar {
        left: 0;
        overflow: auto;
        width: 80%;
      }
    }

    .main-panel {
      margin-left: 0;
    }
  }

  .closed {
    .admin-panel {
      .sidebar {
        &.franchise-sidebar {
          min-width: 0;
          width: 0;
          z-index: 1;
        }
      }

      .header-strip {
        left: 0;
        padding: 10px;
      }

      .main-panel {
        left: 3%;
        min-width: 94%;
        padding: 8px;
        width: 94%;
      }
    }
  }
}
